import DateFormat from "@/libs/DateFormat"

export default class Promotioncode {
    constructor(data = {}) {
        return {
            id: data.id || '',
            code: data.code|| '',
            discounttype: data.discounttype || '',
            discount: data.discount || '',
            description: data.description || '',
            createdby: data.createdby || '',
            createddate: DateFormat.formatDateTime(data.createddate) || '',
        }
    }
}