import { createWebHistory, createRouter } from "vue-router";
import Store from '../store';
import MainLayout from "@/views/main-layout.vue";
import Login from "@/views/login.vue";
import ForgotPassword from "@/views/forgot-password.vue";
import MemberManagement from "@/views/MemberManagement/member-list.vue"
import MemberManagementForm from "@/views/MemberManagement/member-form.vue";
import QuotationList from "@/views/QuotationList/quotation-list.vue"
import OrderList from "@/views/OrderList/order-list.vue"
import OrderForm from "@/views/OrderList/order-form.vue"
import TemplateList from "@/views/Template/template-list.vue"
import TemplateForm from "@/views/Template/template-form.vue"
import PromotionCodeList from "@/views/PromotionCodeManagement/promotioncode-list.vue"
import PromotionCodeForm from "@/views/PromotionCodeManagement/promotioncode-form.vue"
import PrivacyPolicy from "@/views/PrivacyPolicyPage/privacy-policy.vue"
import TermsAndConditions from "@/views/TermsAndConditionsPage/terms-and-conditions.vue"
import PrintingType from "@/views/PrintingType/printing-list.vue"
import PrintingTypeForm from "@/views/PrintingType/printing-form.vue"
import PaperType from "@/views/PaperType/paper-list.vue"
import PaperTypeForm from "@/views/PaperType/paper-form.vue"
import PaperColor from "@/views/PaperColor/paper-color-list.vue"
import PaperColorForm from "@/views/PaperColor/paper-color-form.vue"
import FontType from "@/views/FontType/font-list.vue"
import FontTypeForm from "@/views/FontType/font-form.vue"
import ShippingMethod from "@/views/ShippingMethod/shipping-list.vue"
import ShippingMethodForm from "@/views/ShippingMethod/shipping-form.vue"
import PaymentMethod from "@/views/PaymentMethod/payment-list.vue"
import PaymentMethodForm from "@/views/PaymentMethod/payment-form.vue"
import ProfileSetting from "@/views/ProfileSetting/profile-setting.vue"

const routes = [
    {
        path: "/login",
        name: "Login",
        component: Login,
        meta: {
            title: "Login"
        }
    },
    {
        path: "/forgot-password",
        name: "ForgotPassword",
        component: ForgotPassword,
        meta: {
            title: "Forgot password"
        }
    },
    {
        component: MainLayout,
        children: [
            {
                path: "/member-management",
                name: "MemberManagement",
                component: MemberManagement,
                meta: {
                    requiresAuth: true,
                    module: 'MemberManagement',
                    title: "Member management"
                },
            },
            {
                path: "/member-management/create",
                name: "MemberManagementCreate",
                component: MemberManagementForm,
                meta: {
                    requiresAuth: true,
                    module: 'MemberManagement',
                    title: "Member Management / Add"
                },
            },
            {
                path: "/member-management/edit/:id",
                name: "MemberManagementEdit",
                component: MemberManagementForm,
                meta: {
                    requiresAuth: true,
                    module: 'MemberManagement',
                    title: "Member Management / Edit",
                },
            },
            {
                path: "/quotation-list",
                name: "QuotationList",
                component: QuotationList,
                meta: {
                    requiresAuth: true,
                    module: 'QuotationList',
                    title: "Quotation List"
                },
            },
            {
                path: "/order-list",
                name: "OrderList",
                component: OrderList,
                meta: {
                    requiresAuth: true,
                    module: 'OrderList',
                    title: "Order List"
                },
            },
            {
                path: "/order-list/create",
                name: "OrderListCreate",
                component: OrderForm,
                meta: {
                    requiresAuth: true,
                    module: 'OrderList',
                    title: "Order List / Add"
                },
            },
            {
                path: "/order-list/edit/:id",
                name: "OrderListEdit",
                component: OrderForm,
                meta: {
                    requiresAuth: true,
                    module: 'OrderList',
                    title: "Order List / Edit",
                },
            },
            {
                path: "/template-list",
                name: "TemplateList",
                component: TemplateList,
                meta: {
                    requiresAuth: true,
                    module: 'TemplateList',
                    title: "Template List"
                },
            },
            {
                path: "/template-list/create",
                name: "TemplateListCreate",
                component: TemplateForm,
                meta: {
                    requiresAuth: true,
                    module: 'TemplateList',
                    title: "Template List / Add"
                },
            },
            {
                path: "/template-list/edit/:id",
                name: "TemplateListEdit",
                component: TemplateForm,
                meta: {
                    requiresAuth: true,
                    module: 'TemplateList',
                    title: "Template List / Edit",
                },
            },
            {
                path: "/promotion-code-list",
                name: "PromotionCodeList",
                component: PromotionCodeList,
                meta: {
                    requiresAuth: true,
                    module: 'PromotionCodeList',
                    title: "PromotionCode List"
                },
            },
            {
                path: "/promotion-code-list/create",
                name: "PromotionCodeListCreate",
                component: PromotionCodeForm,
                meta: {
                    requiresAuth: true,
                    module: 'PromotionCodeList',
                    title: "PromotionCode List / Add"
                },
            },
            {
                path: "/promotion-code-list/edit/:id",
                name: "PromotionCodeListEdit",
                component: PromotionCodeForm,
                meta: {
                    requiresAuth: true,
                    module: 'PromotionCodeList',
                    title: "PromotionCode List / Edit",
                },
            },
            {
                path: "/privacy-policy",
                name: "PrivacyPolicy",
                component: PrivacyPolicy,
                meta: {
                    requiresAuth: true,
                    module: 'PrivacyPolicy',
                    title: "Privacy policy"
                },
            },
            {
                path: "/terms-conditions",
                name: "TermsAndConditions",
                component: TermsAndConditions,
                meta: {
                    requiresAuth: true,
                    module: 'TermsAndConditions',
                    title: "Terms and conditions"
                },
            },
            {
                path: "/printing-type",
                name: "PrintingType",
                component: PrintingType,
                meta: {
                    requiresAuth: true,
                    module: 'PrintingType',
                    title: "Printing Type"
                },
            },
            {
                path: "/printing-type/create",
                name: "PrintingTypeCreate",
                component: PrintingTypeForm,
                meta: {
                    requiresAuth: true,
                    module: 'PrintingType',
                    title: "Printing Type / Add"
                },
            },
            {
                path: "/printing-type/edit/:id",
                name: "PrintingTypeEdit",
                component: PrintingTypeForm,
                meta: {
                    requiresAuth: true,
                    module: 'PrintingType',
                    title: "Printing Type / Edit",
                },
            },
            {
                path: "/paper-type",
                name: "PaperType",
                component: PaperType,
                meta: {
                    requiresAuth: true,
                    module: 'PaperType',
                    title: "Paper Type"
                },
            },
            {
                path: "/paper-type/create",
                name: "PaperTypeCreate",
                component: PaperTypeForm,
                meta: {
                    requiresAuth: true,
                    module: 'PaperType',
                    title: "Paper Type / Add"
                },
            },
            {
                path: "/paper-type/edit/:id",
                name: "PaperTypeEdit",
                component: PaperTypeForm,
                meta: {
                    requiresAuth: true,
                    module: 'PaperType',
                    title: "Paper Type / Edit",
                },
            },
            {
                path: "/paper-color",
                name: "PaperColor",
                component: PaperColor,
                meta: {
                    requiresAuth: true,
                    module: 'PaperColor',
                    title: "Paper Color"
                },
            },
            {
                path: "/paper-color/create",
                name: "PaperColorCreate",
                component: PaperColorForm,
                meta: {
                    requiresAuth: true,
                    module: 'PaperColor',
                    title: "Paper Color / Add"
                },
            },
            {
                path: "/paper-color/edit/:id",
                name: "PaperColorEdit",
                component: PaperColorForm,
                meta: {
                    requiresAuth: true,
                    module: 'PaperColor',
                    title: "Paper Color / Edit",
                },
            },
            {
                path: "/font-type",
                name: "FontType",
                component: FontType,
                meta: {
                    requiresAuth: true,
                    module: 'FontType',
                    title: "Font Type"
                },
            },
            {
                path: "/font-type/create",
                name: "FontTypeCreate",
                component: FontTypeForm,
                meta: {
                    requiresAuth: true,
                    module: 'FontType',
                    title: "Font Type / Add"
                },
            },
            {
                path: "/font-type/edit/:id",
                name: "FontTypeEdit",
                component: FontTypeForm,
                meta: {
                    requiresAuth: true,
                    module: 'FontType',
                    title: "Font Type / Edit",
                },
            },
            {
                path: "/shipping-method",
                name: "ShippingMethod",
                component: ShippingMethod,
                meta: {
                    requiresAuth: true,
                    module: 'ShippingMethod',
                    title: "Shipping Method"
                },
            },
            {
                path: "/shipping-method/create",
                name: "ShippingMethodCreate",
                component: ShippingMethodForm,
                meta: {
                    requiresAuth: true,
                    module: 'ShippingMethod',
                    title: "Shipping Method / Add"
                },
            },
            {
                path: "/shipping-method/edit/:id",
                name: "ShippingMethodEdit",
                component: ShippingMethodForm,
                meta: {
                    requiresAuth: true,
                    module: 'ShippingMethod',
                    title: "Shipping Method / Edit",
                },
            },
            {
                path: "/payment-method",
                name: "PaymentMethod",
                component: PaymentMethod,
                meta: {
                    requiresAuth: true,
                    module: 'PaymentMethod',
                    title: "Payment Method"
                },
            },
            {
                path: "/payment-method/create",
                name: "PaymentMethodCreate",
                component: PaymentMethodForm,
                meta: {
                    requiresAuth: true,
                    module: 'PaymentMethod',
                    title: "Payment Method / Add"
                },
            },
            {
                path: "/payment-method/edit/:id",
                name: "PaymentMethodEdit",
                component: PaymentMethodForm,
                meta: {
                    requiresAuth: true,
                    module: 'PaymentMethod',
                    title: "Payment Method / Edit",
                },
            },
            {
                path: "/profile-setting",
                name: "ProfileSetting",
                component: ProfileSetting,
                meta: {
                    requiresAuth: true,
                    module: 'ProfileSetting',
                    title: "Profile Setting",
                },
            },
        ]
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    const title = 'OrderApp';
    if (Store.state.timeout == null || (Store.state.timeout != null && Store.state.timeout > new Date())) {
        if (to.matched.some(record => record.meta.requiresAuth)) {
            if (Store.state.hasLoggedIn) {
                Store.commit('setUserTimeout');
                Store.commit('setActiveRoute', to.meta.module);
                window.document.title = to.meta && to.meta.title ? title + ' - ' + to.meta.title : title;
                next()
                return
            }
            Store.dispatch("logout");
            next('/login')
            return
        } else if (to.name === undefined) {
            if (Store.state.hasLoggedIn) {
                Store.commit('setActiveRoute', to.meta.module);
                window.document.title = to.meta && to.meta.title ? title + ' - ' + to.meta.title : title;
                next()
                return
            }
            Store.dispatch("logout");
            next('/login')
            return
        } else {
            Store.commit('setUserTimeout');
            if (to.name == "Login" && Store.state.hasLoggedIn) {
                Store.commit('setActiveRoute', 'MemberManagement');
                next("/member-management");
                return
            } else {
                window.document.title = to.meta && to.meta.title ? title + ' - ' + to.meta.title : title;
                next()
                return
            }            
        }
    } else {        
        Store.dispatch("logout");
        next("/login");
        return
    }
})

export default router;