<template>
  <Dialog header="Printing Information" v-model:visible="display" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw', height: '50vw'}" @hide="hideModal" :modal="true">
    <div v-if="pdfPath !== ''">
    <embed :src="pdfPath" style="width: 730px; height: 100vh"/>
    </div>
  </Dialog>
</template>

<script>
import Dialog from "primevue/dialog";
import APIService from "@/services/api-service";

export default {
  components: {
    Dialog,
  },
  data() {
    return {
      display: false,
      pdfPath: "",
    };
  },
  props: {
    showPopup: {
      type: Boolean,
      default: false,
      required: true,
    },
    orderId: {
      type: String,
    },
  },
  watch: {
    showPopup: {
      handler(newValue) {
        if (newValue === true) {
          this.openPopup();
          this.gotoOrderPrint();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    openPopup() {
      this.display = true;
    },
    hideModal() {      
      this.pdfPath = ""
      this.display = false;
      this.$emit("hidemodal")
    },
    // getPdf() {
    //   return `${process.env.VUE_APP_ORDER_PDF_BASE_PATH}${this.orderId}.pdf`;
    // },
    gotoOrderPrint() {
        APIService.get(`/order/printorder/${this.orderId}`).then((response) => {
          if (response && response.data && response.data.success) {
            this.pdfPath = `${process.env.VUE_APP_ORDER_PDF_BASE_PATH}${this.orderId}.pdf`
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        });
    },
  },
};
</script>