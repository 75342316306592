<template>
  <div class="list-margin">
    <DataTable
      :value="templates"
      :paginator="true"
      :lazy="true"
      class="p-datatable-lg p-datatable-customers"
      :rows="itemsPerPage"
      :totalRecords="totalRecords"
      dataKey="printingtype"
      :rowHover="true"
      :resizableColumns="true"
      @page="onPage($event)"
      @sort="onSort($event)"
      paginatorTemplate="PrevPageLink PageLinks NextPageLink CurrentPageReport"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} results"
    >
      <template #header>
        <div class="flex">
          <div class="col-6 text-left list-header">Template</div>
          <div class="col-6 text-right ml-4">
            <PrimeButton
              label="Add Template"
              class="add-button mr-3"
              icon="plus-icon"
              @click="gotoCreateTemplate"
            ></PrimeButton>
            <div class="p-input-icon-right">
              <i class="search-icon cursor-pointer" @click="onSearch" />
              <InputText
                v-model="search"
                placeholder="search"
                class="search-input"
              />
            </div>
          </div>
        </div>
      </template>
      <template #loading> Loading records, please wait... </template>
      <template #empty> No records found. </template>
      <Column field="templatename" header="Template" sortable="true">
        <template #body="row">
          <div class="flex">
            <img
              :src="getImagePath(row.data.templateimage)"
              alt=""
              class="img-template"
            />
            <span class="margin-username">{{row.data.templatename}}</span>
          </div>
        </template>
      </Column>
      <Column
        field="printingtype"
        header="Printing Type"
        sortable="true"
      ></Column>
      <Column field="createdby" header="Created By" sortable="true"></Column>
      <Column field="createddate" header="Created At" sortable="true"></Column>
      <Column field="actions" header="Actions">
        <template #body="data">
          <div class="flex">
            <PrimeButton
              label="Edit"
              class="edit-button ml-2"
              @click="gotoTemplateEdit(data.data.id)"
            />
            <PrimeButton
              label="Delete"
              class="delete-button ml-2"
              @click="showDeletePopup(data.data.id)"
            />
          </div>
        </template>
      </Column>
    </DataTable>
    <div>
      <ConfirmPopup
        :show-popup="showPopup"
        @confirmYes="deleteTemplate"
        @confirmNo="showPopup = false"
      ></ConfirmPopup>
    </div>
  </div>
</template>

<script>
import APIService from "@/services/api-service.js"
import TemplateFactory from "@/factories/template"

export default {
  data() {
    return {
      currentPage: 0,
      itemsPerPage: 10,
      search: "",
      totalRecords: 0,
      sortColumn: "id",
      sortDir: "asc",
      templateIdForDelete: "",
      showPopup: false,
      templates: [],
      confirmType: "",
    };
  },
    mounted() {
      this.getTemplateList();
    },
  methods: {
    gotoCreateTemplate() {
      this.$router.push({ name: "TemplateListCreate" });
    },
    gotoTemplateEdit(templateId) {
      this.$router.push({
        name: "TemplateListEdit",
        params: { id: templateId },
      });
    },
    getImagePath(name) {
      return process.env.VUE_APP_TEMPLATE_IMAGE_BASE_PATH + name;
    },
    showDeletePopup(templateId) {
      this.templateIdForDelete = templateId;
      this.showPopup = true;
    },
    getTemplateList() {
      this.templates = [];
      APIService.get(
        `/template/${this.currentPage}/${this.itemsPerPage}/${this.sortColumn}/${this.sortDir}/${this.search}`
      ).then((response) => {
        if (response && response.data) {
          this.totalRecords = response.data.totalrecord;
          this.templates = TemplateFactory.createFromJsonArray(response.data.data);
        }
      });
    },
    deleteTemplate() {
      APIService.delete(`/template/delete/${this.templateIdForDelete}`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            this.getTemplateList();
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.showPopup = false;
        });
    },
     onPage(pageData) {
      this.currentPage = pageData.first;
      this.getTemplateList();
    },
    onSort(sortData) {
      let sortField = sortData.sortField;
      if (sortData.sortField === "createdby") {
        sortField = "createdby";
      } else if (sortData.sortField === "createddate") {
        sortField = "createddate";
      }
      this.sortColumn = sortField;
      this.sortDir = sortData.sortOrder === 1 ? "asc" : "dsc";
      this.currentPage = 0;

      this.getTemplateList();
    },
    onSearch() {
      this.search = this.search.trimStart().trimEnd();
      this.currentPage = 0;
      this.getTemplateList();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/datatable.scss";
.margin-username {
  margin-top: 22px;
  margin-left: 10px;
}

.img-template {
  height: 64px;
  width: 64px;
}
</style>