<template>
  <div class="list-margin">
    <DataTable
      :value="orders"
      :paginator="true"
      :lazy="true"
      v-model:expandedRows="expandedRows"
      class="p-datatable-lg p-datatable-customers"
      :rows="itemsPerPage"
      :totalRecords="totalRecords"
      dataKey="orderno"
      :rowHover="true"
      :resizableColumns="true"
      @page="onPage($event)"
      @sort="onSort($event)"
      paginatorTemplate="PrevPageLink PageLinks NextPageLink CurrentPageReport"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} results"
      expandedRowIcon="chevron-up-icon"
      collapsedRowIcon="chevron-down-icon"
    >
      <template #header>
        <div class="flex">
          <div class="col-6 list-header">Order List</div>
          <div class="col-6 text-right ml-4 list-header">
            <PrimeButton
              label="Add Order List"
              class="add-button mr-3"
              icon="plus-icon"
              @click="gotoCreateOrder"
            ></PrimeButton>
            <div class="p-input-icon-right">
              <i class="search-icon cursor-pointer" @click="onSearch" />
              <InputText
                v-model="search"
                placeholder="Search"
                class="search-input"
              />
            </div>
          </div>
        </div>
      </template>
      <template #loading> Loading records, please wait... </template>
      <template #empty> No records found. </template>
      <Column field="expand" :expander="true">
        <template #header>
          <span class="p-column-title"></span>
          <button
            class="p-row-toggler p-link header-toggle"
            type="button"
            @click="arrowToggle"
          >
            <span
              :class="{
                'p-row-toggler-icon pi chevron-down-icon': !isToggled,
                'p-row-toggler-icon pi chevron-up-icon': isToggled,
              }"
            ></span>
          </button>
        </template>
      </Column>
      <Column field="orderno" header="Order #" sortable="true">
        <template #body="data">
          <span class="underline">#{{ data.data.orderno }}</span>
        </template>
      </Column>
      <Column field="orderdate" header="Order Date" sortable="true"></Column>
      <Column
        field="referquotationnumber"
        header="Refer Quotation #"
        sortable="true"
      >
        <template #body="data">
          <span>#{{ data.data.referquotationnumber }}</span>
        </template>
      </Column>
      <Column
        field="printingtype"
        header="Printing Type"
        sortable="true"
      ></Column>
      <Column field="templatename" header="Template" sortable="true"></Column>
      <Column field="quantity" header="Quantity" sortable="true"></Column>
      <Column field="price" header="Price" sortable="true"></Column>
      <Column field="status" header="Status" sortable="true">
        <template #body="data">
          <Chip
            v-if="data.data.status == 'confirmed'"
            :label="data.data.status"
            class="chip-style-for-order-confirm"
          />
          <Chip
            v-if="data.data.status == 'processing'"
            :label="data.data.status"
            class="chip-style-for-order-process"
          />
          <Chip
            v-if="data.data.status == 'transporting'"
            :label="data.data.status"
            class="chip-style-for-order-transport"
          />
          <Chip
            v-if="data.data.status == 'notified'"
            :label="data.data.status"
            class="chip-style-for-order-notified"
          />
          <Chip
            v-if="data.data.status == 'completed'"
            :label="data.data.status"
            class="chip-style-for-order-completed"
          />
        </template>
      </Column>
      <Column field="actions" header="Actions">
        <template #body="data">
          <div class="flex">
            <PrimeButton
              label="Print"
              class="print-button ml-2"
              @click="showPrintPopup(data.data.id)"
            />
            <PrimeButton
              label="Edit"
              class="edit-button ml-2"
              @click="gotoOrderEdit(data.data.id)"
            />
            <PrimeButton
              label="Delete"
              class="delete-button ml-2"
              @click="showDeletePopup(data.data.id)"
            />
          </div>
        </template>
      </Column>

      <template #expansion="slotProps">
        <div class="col-12">
          <span class="quotation-text"
            >Quotation #{{ slotProps.data.referquotationnumber }}</span
          >
        </div>
        <div class="row col-12 flex">
          <div class="col-3">
            <span class="quotation-sub-text">Quotation Detail</span>
            <div class="grid mt-2">
              <div class="col-6 quotation-detail-label text-left">
                Order Number
              </div>
              <div class="col-6 quotation-detail-value text-right">
                #{{ slotProps.data.orderno }}
              </div>
            </div>
            <div class="grid mt-2">
              <div class="col-6 quotation-detail-label text-left">
                Order Date
              </div>
              <div class="col-6 quotation-detail-value text-right">
                {{ slotProps.data.orderdate }}
              </div>
            </div>
            <div class="grid mt-2">
              <div class="col-6 quotation-detail-label text-left">
                Refer Quotation Number
              </div>
              <div class="col-6 quotation-detail-value text-right">
                #{{ slotProps.data.referquotationnumber }}
              </div>
            </div>
            <div class="grid mt-2">
              <div class="col-6 quotation-detail-label text-left">Status</div>
              <div class="col-6 quotation-detail-value text-right">
                <Chip
                  v-if="slotProps.data.status == 'confirmed'"
                  :label="slotProps.data.status"
                  class="chip-style-for-order-confirm chip-margin"
                />
                <Chip
                  v-if="slotProps.data.status == 'processing'"
                  :label="slotProps.data.status"
                  class="chip-style-for-order-process chip-margin"
                />
                <Chip
                  v-if="slotProps.data.status == 'transporting'"
                  :label="slotProps.data.status"
                  class="chip-style-for-order-transport chip-margin"
                />
                <Chip
                  v-if="slotProps.data.status == 'notified'"
                  :label="slotProps.data.status"
                  class="chip-style-for-order-notified chip-margin"
                />
                <Chip
                  v-if="slotProps.data.status == 'completed'"
                  :label="slotProps.data.status"
                  class="chip-style-for-order-completed chip-margin"
                />
              </div>
            </div>
            <div class="grid mt-3">
              <div class="col-3 text-left">
                <img
                  :src="getImagePath(slotProps.data.templateimage)"
                  alt=""
                  style="height: 72px; width: 72px"
                />
              </div>
              <div class="col-6 quotation-detail-value text-left">
                <div class="row mt-3 quotation-detail-label">
                  {{ slotProps.data.printingtype }}
                </div>
                <div class="row">
                  {{ slotProps.data.templatename }}
                </div>
              </div>
              <div class="col-3 quotation-detail-value text-right mt-4">
                × {{ slotProps.data.quantity }}
              </div>
            </div>
          </div>
          <div class="col-3">
            <span class="quotation-sub-text">Printing Material</span>
            <div class="grid mt-2">
              <div class="col-6 quotation-detail-label text-left">
                Paper Type
              </div>
              <div class="col-6 quotation-detail-value text-right">
                {{ slotProps.data.printingmaterial.papertype }}
              </div>
            </div>
            <div class="grid mt-2">
              <div class="col-6 quotation-detail-label text-left">
                Paper Color
              </div>
              <div class="col-6 quotation-detail-value text-right">
                {{ slotProps.data.printingmaterial.papercolor }}
              </div>
            </div>
            <div class="grid mt-2">
              <div class="col-6 quotation-detail-label text-left">
                Font Type
              </div>
              <div class="col-6 quotation-detail-value text-right">
                {{ slotProps.data.printingmaterial.fonttype }}
              </div>
            </div>
            <div class="grid mt-2">
              <div class="col-6 quotation-detail-label text-left">
                Font Color
              </div>
              <div class="col-6 quotation-detail-value text-right">
                <ColorPicker
                  v-model="slotProps.data.printingmaterial.fontcolor"
                  class="color-field"
                  disabled="true"
                />
                {{ slotProps.data.printingmaterial.fontcolor }}
              </div>
            </div>
          </div>
          <div class="col-3">
            <span class="quotation-sub-text">Print Out Information</span>
            <div class="grid mt-2">
              <div class="col-6 quotation-detail-label text-left">
                Company Name
              </div>
              <div class="col-6 quotation-detail-value text-right">
                {{ slotProps.data.printoutinformation.companyname }}
              </div>
            </div>
            <div class="grid mt-2">
              <div class="col-6 quotation-detail-label text-left">Name</div>
              <div class="col-6 quotation-detail-value text-right">
                {{ slotProps.data.printoutinformation.name }}
              </div>
            </div>
            <div class="grid mt-2">
              <div class="col-6 quotation-detail-label text-left">Title</div>
              <div class="col-6 quotation-detail-value text-right">
                {{ slotProps.data.printoutinformation.title }}
              </div>
            </div>
            <div class="grid mt-2">
              <div class="col-6 quotation-detail-label text-left">
                Business Phone Number
              </div>
              <div class="col-6 quotation-detail-value text-right">
                {{ slotProps.data.printoutinformation.businessphonenumber }}
              </div>
            </div>
            <div class="grid mt-2">
              <div class="col-6 quotation-detail-label text-left">
                Direct Line
              </div>
              <div class="col-6 quotation-detail-value text-right">
                {{ slotProps.data.printoutinformation.directline }}
              </div>
            </div>
            <div class="grid mt-2">
              <div class="col-6 quotation-detail-label text-left">
                Mobile Number
              </div>
              <div class="col-6 quotation-detail-value text-right">
                {{ slotProps.data.printoutinformation.mobilenumber }}
              </div>
            </div>
            <div class="grid mt-2">
              <div class="col-6 quotation-detail-label text-left">Address</div>
              <div class="col-6 quotation-detail-value text-right truncate">
                {{ slotProps.data.printoutinformation.address }}
              </div>
            </div>
            <div class="grid mt-2">
              <div class="col-6 quotation-detail-label text-left">Email</div>
              <div class="col-6 quotation-detail-value text-right">
                {{ slotProps.data.printoutinformation.email }}
              </div>
            </div>
            <div class="grid mt-2">
              <div class="col-6 quotation-detail-label text-left">
                Uploaded File
              </div>
              <div class="col-6 quotation-detail-value text-right">
                <a
                  class="underline file-color cursor-pointer"
                  @click="getURL(slotProps.data.uploadfile)"
                  >{{ slotProps.data.uploadoriginalfile }}</a
                >
              </div>
            </div>
          </div>
          <div class="col-3">
            <!-- <span class="quotation-sub-text">Printing Material</span> -->
            <div class="grid mt-5">
              <div class="col-6 quotation-detail-label text-left">
                Promotion Code
              </div>
              <div class="col-6 quotation-detail-value text-right">
                {{ slotProps.data.promocode }}
              </div>
            </div>
            <div class="grid mt-2">
              <div class="col-6 quotation-detail-label text-left">
                Shipping Method
              </div>
              <div class="col-6 quotation-detail-value text-right">
                {{ slotProps.data.shippingmethod }}
              </div>
            </div>
            <div class="grid mt-2">
              <div class="col-6 quotation-detail-label text-left">
                Payment Method
              </div>
              <div class="col-6 quotation-detail-value text-right">
                {{ slotProps.data.paymentmethod }}
              </div>
            </div>
            <div class="grid mt-2">
              <div class="col-6 quotation-detail-label text-left">Price</div>
              <div class="col-6 price-value text-right">
                ${{ slotProps.data.price }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </DataTable>
    <div>
      <!-- <vue3-html2pdf
        :show-layout="false"
        :float-layout="true"
        :preview-modal="true"
        :paginate-elements-by-height="1400"
        :filename="filename"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-content-width="100%"
        pdf-orientation="landscape"
        ref="html2Pdf"
      >
        <template v-slot:pdf-content>
          <ContentToPrint :data="orderInfo" />
        </template>
      </vue3-html2pdf> -->
    </div>
    <div>
      <ConfirmPopup
        :show-popup="showPopup"
        @confirmYes="deleteOrder"
        @confirmNo="showPopup = false"
      ></ConfirmPopup>
    </div>
    <div>
      <ContentToPrint
        :show-popup="showPopupPrint"
        :order-id="orderIdForPrint"
        @hidemodal="showPopupPrint = false"
      ></ContentToPrint>
    </div>
  </div>
</template>

<script>
import APIService from "@/services/api-service";
import OrderFactory from "@/factories/order";
// import Vue3Html2pdf from "vue3-html2pdf";
import ContentToPrint from "../../components/modal-popup/ContentToPrint.vue";
// import moment from "moment";

export default {
  components: {
    // Vue3Html2pdf,
    ContentToPrint,
  },
  data() {
    return {
      orderInfo: "",
      filename: "",
      orderIdForPrint: "",
      showPopupPrint: false,
      currentPage: 0,
      itemsPerPage: 10,
      search: "",
      totalRecords: 0,
      sortColumn: "id",
      sortDir: "asc",
      showPopup: false,
      isToggled: false,
      orderIdForDelete: "",
      expandedRows: [],
      orders: [
        {
          order: "#98765432",
          orderdate: "2022-10-10",
          referquotation: "#12345678",
          printingtype: "Name Card",
          template: "Name Card Type A",
          quantity: "400",
          price: "$900",
          status: "Waiting",
        },
        {
          order: "#98765432",
          orderdate: "2022-10-10",
          referquotation: "#12345678",
          printingtype: "Name Card",
          template: "Name Card Type A",
          quantity: "400",
          price: "$900",
          status: "Waiting",
        },
      ],
    };
  },
  mounted() {
    this.getOrderList();
  },
  methods: {
    gotoCreateOrder() {
      this.$router.push({ name: "OrderListCreate" });
    },
    showPrintPopup(orderId) {
      this.orderIdForPrint = orderId;
      this.showPopupPrint = true;
    },
    // gotoOrderPrint(orderId) {
    //   // let file_name = orderno + "-" + moment(new Date()).format("YYYY-MM-DD");
    //   // this.filename = file_name;
    //   this.orderIdForPrint = orderId;
    //   APIService.get(`/order/printorder/${this.orderIdForPrint}`).then(
    //     (response) => {
    //       if (response && response.data && response.data.success) {
    //         this.showPopupPrint = true;
    //         // this.orderInfo = response.data.data;
    //         // this.$refs.html2Pdf.generatePdf();
    //       } else {
    //         this.$toast.add({
    //           severity: "error",
    //           summary: "Error",
    //           detail: response.data.message,
    //           life: 3000,
    //         });
    //       }
    //     }
    //   );
    // },
    gotoOrderEdit(orderId) {
      this.$router.push({
        name: "OrderListEdit",
        params: { id: orderId },
      });
    },
    expandAll() {
      this.expandedRows = this.orders;
    },
    collapseAll() {
      this.expandedRows = null;
    },
    arrowToggle() {
      if (this.isToggled) {
        this.collapseAll();
      } else {
        this.expandAll();
      }
      return (this.isToggled = !this.isToggled);
    },
    getImagePath(name) {
      return process.env.VUE_APP_TEMPLATE_IMAGE_BASE_PATH + name;
    },
    getTextPath(name) {
      return process.env.VUE_APP_ORDER_TEXT_BASE_PATH + name;
    },
    getURL(file) {
      const url = process.env.VUE_APP_ORDER_TEXT_BASE_PATH + file;
      window.open(url, "_blank");
    },
    showDeletePopup(orderId) {
      this.orderIdForDelete = orderId;
      this.showPopup = true;
    },
    getOrderList() {
      this.orders = [];
      APIService.get(
        `/order/${this.currentPage}/${this.itemsPerPage}/${this.sortColumn}/${this.sortDir}/${this.search}`
      ).then((response) => {
        if (response && response.data) {
          this.totalRecords = response.data.totalrecord;
          this.orders = OrderFactory.createFromJsonArray(response.data.data);
        }
      });
    },
    deleteOrder() {
      APIService.delete(`/order/delete/${this.orderIdForDelete}`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            this.getOrderList();
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.showPopup = false;
        });
    },
    onPage(pageData) {
      this.currentPage = pageData.first;
      this.getOrderList();
    },
    onSort(sortData) {
      let sortField = sortData.sortField;
      if (sortData.sortField === "createdby") {
        sortField = "createdby";
      } else if (sortData.sortField === "createddate") {
        sortField = "createddate";
      }
      this.sortColumn = sortField;
      this.sortDir = sortData.sortOrder === 1 ? "asc" : "dsc";
      this.currentPage = 0;

      this.getOrderList();
    },
    onSearch() {
      this.search = this.search.trimStart().trimEnd();
      this.currentPage = 0;
      this.getOrderList();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/datatable.scss";

.header-toggle {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: box-shadow 0.15s;
}

.card-margin-total {
  margin-left: 110px;
}

.card-margin-promo {
  margin-left: -19px;
}

.card-margin-discount {
  margin-left: 84px;
}

::v-deep(.expand-table) {
  .p-datatable-table {
    padding-bottom: 8px;
  }

  .p-datatable-wrapper {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

::v-deep(.text-field) {
  .p-inputtext {
    height: 48px;
    width: 120px;
  }
}

::v-deep(.color-field) {
  .p-inputtext {
    width: 20px;
    height: 20px;
    background: #1b1b1b;
    border-radius: 2px;
  }
}

.waiting-status-chip {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 2px 8px;
  gap: 8px;
  width: 75px;
  height: 20px;
  background: linear-gradient(
      0deg,
      rgba(235, 148, 50, 0.1),
      rgba(235, 148, 50, 0.1)
    ),
    #ffffff;
  border-radius: 4px;

  font-family: OpenSans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #eb9432;
}

.chip-margin {
  margin-left: auto;
}

.underline {
  text-decoration-line: underline;
}

.file-color {
  color: #ee3a3a;
}

.truncate {
  text-overflow: ellipsis;
  word-break: break-all;
  overflow: hidden;
  white-space: nowrap;
  height: 28px;
}

.truncate:hover {
  overflow: visible;
  white-space: normal;
  height: auto;
}
</style>