<template>
  <div class="ml-8 mr-8 margin-top mb-5">
    <div class="grid">
      <div class="col-12 order-form-title">{{ title }}</div>
      <div class="order-card w-full">
        <div class="col-12">
          <div class="grid">
            <div class="col-3">
              <div class="order-form-sub-title">Order Detail</div>
            </div>
            <div class="col-9">
              <div class="grid">
                <div class="col-6">
                  <InputTextRequire
                    v-model="vmodel.referquotationnumber"
                    :validation="v$.vmodel.referquotationnumber"
                    sectionClass="field mt-1"
                    id="quotationnumber"
                    label="Refer Quotation Number"
                    placeholder="Refer Quotation Number"
                    type="number"
                    v-if="!isEditMode"
                  />
                  <InputTextRequire
                    v-model="vmodel.referquotationnumber"
                    :validation="v$.vmodel.referquotationnumber"
                    sectionClass="field mt-1"
                    id="quotationnumber"
                    label="Refer Quotation Number"
                    placeholder="Refer Quotation Number"
                    type="number"
                    class="disable-text-field"
                    :disabled="true"
                    v-else
                  />
                </div>
                <div
                  class="col-6"
                  v-if="vmodel.referquotationnumber.length === 8"
                >
                  <PrimeButton
                    label="Get Quatation"
                    class="order-form-button mt-2"
                    v-if="!isEditMode"
                    @click="getQuatation"
                    style="width: max-content; margin-top: 40px !important"
                  ></PrimeButton>
                </div>
              </div>
              <div class="grid">
                <div class="col-6">
                  <InputTextRequire
                    v-model="vmodel.orderno"
                    :validation="v$.vmodel.orderno"
                    sectionClass="field mt-1"
                    id="ordernumber"
                    label="Order Number (Auto Generate)"
                    placeholder="Order Number"
                    class="disable-text-field"
                    :disabled="true"
                  />
                </div>
                <div class="col-6">
                  <InputDate
                    v-model="vmodel.orderdate"
                    :validation="v$.vmodel.orderdate"
                    sectionClass="field mt-1"
                    id="orderdate"
                    label="Order Date"
                    placeholder="Order Date"
                  />
                </div>
              </div>
              <DropDownRequire
                v-model="vmodel.userid"
                :validation="v$.vmodel.userid"
                sectionClass="field mt-1"
                id="User"
                label="Member"
                placeholder="Select Member"
                :options="memberList"
                optionLabel="username"
                optionValue="id"
                v-if="!isEditMode"
                class="dropdown"
              />
              <DropDownRequire
                v-model="vmodel.userid"
                :validation="v$.vmodel.userid"
                sectionClass="field mt-1"
                id="User"
                label="Member"
                placeholder="Select Member"
                :options="memberList"
                optionLabel="username"
                optionValue="id"
                :disabled="true"
                class="dropdown"
                v-else
              />
              <DropDownRequire
                v-model="vmodel.printingtype"
                :validation="v$.vmodel.printingtype"
                sectionClass="field mt-1"
                id="printingtype"
                label="Printing Type"
                placeholder="Select Printing Type"
                :options="printingTypeList"
                optionLabel="name"
                optionValue="id"
                class="dropdown"
                @update:modelValue="getTemplateByPrintingType"
              />

              <div v-if="vmodel.printingtype" class="form-label">Template</div>

              <div v-if="vmodel.printingtype" class="grid mt-2">
                <template
                  v-for="(template, index) in templateList"
                  :key="index"
                >
                  <div class="col-3">
                    <div
                      :class="
                        template.id === vmodel.template
                          ? 'card-box template-selected'
                          : 'card-box'
                      "
                    >
                      <div>
                        <img
                          :src="getImagePath(template.templateimage)"
                          alt=""
                          style="height: 155px"
                          @click="setTemplateId(template.id)"
                        />
                      </div>
                      <div class="img-name-text text-truncate">
                        {{ template.templatename }}
                      </div>
                    </div>
                  </div>
                </template>
              </div>

              <div v-if="vmodel.printingtype">
                <Paginator
                  :rows="itemsPerPage"
                  :totalRecords="totalRecords"
                  @page="onPageChange($event)"
                  template="PageLinks"
                  class="paginator"
                ></Paginator>
              </div>

              <div class="header-text text-left mt-3">Printing Material</div>
              <div class="grid mt-3">
                <div class="col-6">
                  <DropDownRequire
                    v-model="vmodel.printingmaterial.papertype"
                    :validation="v$.vmodel.printingmaterial.papertype"
                    sectionClass="field mt-1"
                    id="papertype"
                    label="Paper Type"
                    placeholder="Paper Type"
                    :options="paperTypeList"
                    optionLabel="name"
                    optionValue="id"
                    class="dropdown"
                  />
                </div>
                <div class="col-6">
                  <DropDownRequire
                    v-model="vmodel.printingmaterial.papercolor"
                    :validation="v$.vmodel.printingmaterial.papercolor"
                    sectionClass="field mt-1"
                    id="papercolor"
                    label="Paper Color"
                    placeholder="Paper Color"
                    :options="paperColorList"
                    optionLabel="name"
                    optionValue="id"
                    class="dropdown"
                  />
                </div>
              </div>
              <div class="grid">
                <div class="col-6">
                  <DropDownRequire
                    v-model="vmodel.printingmaterial.fonttype"
                    :validation="v$.vmodel.printingmaterial.fonttype"
                    sectionClass="field mt-1"
                    id="fonttype"
                    label="Font Type"
                    placeholder="Font Type"
                    :options="fontTypeList"
                    optionLabel="name"
                    optionValue="id"
                    class="dropdown"
                  />
                </div>
                <div class="col-6">
                  <label for="color" class="form-label margin-color"
                    >Font Color</label
                  >
                  <div v-if="!isEditMode" id="color" class="font-color-box">
                    <ColorPicker
                      v-model="vmodel.printingmaterial.fontcolor"
                      class="color-field"
                    />
                    #{{ vmodel.printingmaterial.fontcolor }}
                  </div>
                  <div v-if="isEditMode" id="color" class="font-color-box">
                    <ColorPicker
                      v-model="vmodel.printingmaterial.fontcolor"
                      class="color-field"
                    />
                    {{ vmodel.printingmaterial.fontcolor }}
                  </div>
                </div>
              </div>
              <div class="grid">
                <div class="col-6 header-text text-left">
                  Print Out Information
                </div>
                <div class="col-6 optional-header-text text-right">
                  OPTIONAL
                </div>
              </div>
              <div class="grid mt-4">
                <div class="col-6">
                  <InputTextRequire
                    v-model="vmodel.printoutinformation.companyname"
                    :validation="v$.vmodel.printoutinformation.companyname"
                    sectionClass="field mt-1"
                    id="companyname"
                    label="Company Name"
                    placeholder="Company Name"
                  />
                </div>
                <div class="col-6">
                  <InputTextRequire
                    v-model="vmodel.printoutinformation.name"
                    :validation="v$.vmodel.printoutinformation.name"
                    sectionClass="field mt-1"
                    id="name"
                    label="Name"
                    placeholder="Name"
                  />
                </div>
              </div>
              <div class="grid">
                <div class="col-6">
                  <InputTextRequire
                    v-model="vmodel.printoutinformation.title"
                    :validation="v$.vmodel.printoutinformation.title"
                    sectionClass="field mt-1"
                    id="title"
                    label="Title"
                    placeholder="Title"
                  />
                </div>
                <div class="col-6">
                  <InputTextRequire
                    v-model="vmodel.printoutinformation.businessphonenumber"
                    :validation="
                      v$.vmodel.printoutinformation.businessphonenumber
                    "
                    sectionClass="field mt-1"
                    id="businessphonenumber"
                    label="Business Phone Number"
                    placeholder="Business Phone Number"
                  />
                </div>
              </div>
              <div class="grid">
                <div class="col-6">
                  <InputTextRequire
                    v-model="vmodel.printoutinformation.directline"
                    :validation="v$.vmodel.printoutinformation.directline"
                    sectionClass="field mt-1"
                    id="directline"
                    label="Direct Line"
                    placeholder="Direct Line"
                  />
                </div>
                <div class="col-6">
                  <InputTextRequire
                    v-model="vmodel.printoutinformation.mobilenumber"
                    :validation="v$.vmodel.printoutinformation.mobilenumber"
                    sectionClass="field mt-1"
                    id="mobilenumber"
                    label="Mobile Number"
                    placeholder="Mobile Number"
                  />
                </div>
              </div>
              <div class="grid">
                <div class="col-6">
                  <InputTextRequire
                    v-model="vmodel.printoutinformation.address"
                    :validation="v$.vmodel.printoutinformation.address"
                    sectionClass="field mt-1"
                    id="address"
                    label="Address"
                    placeholder="Address"
                  />
                </div>
                <div class="col-6">
                  <InputTextRequire
                    v-model="vmodel.printoutinformation.email"
                    :validation="v$.vmodel.printoutinformation.email"
                    sectionClass="field mt-1"
                    id="email"
                    label="Email"
                    placeholder="Email"
                  />
                </div>
              </div>
              <div class="field">
                <label for="photo" class="form-label"
                  >Upload Content to Print</label
                >
                <div
                  class="photo-section cursor-pointer"
                  @click="openFileUpload"
                >
                  <span
                    v-if="vmodel.uploadfile !== ''"
                    class="upload-text-style"
                    >{{ vmodel.uploadoriginalfile }}</span
                  >
                  <span
                    v-if="vmodel.uploadfile == ''"
                    class="upload-icon"
                  ></span>
                  <div v-if="vmodel.uploadfile == ''" class="upload-text">
                    Upload Content to Print
                  </div>
                  <div v-if="vmodel.uploadfile == ''" class="upload-text-small">
                    Txt format
                  </div>
                </div>
                <FileUpload
                  ref="photo"
                  name="photo"
                  accept=".txt"
                  @select="onAdvancedUpload($event)"
                  v-show="false"
                  :aria-describedby="`photo-help`"
                />
              </div>
              <div class="grid">
                <div class="col-6">
                  <div class="field mt-1">
                    <label for="promotioncode" class="form-label"
                      >Promotion Code</label
                    >
                    <InputText
                      id="promotioncode"
                      label="Promotion Code"
                      placeholder="Promotion Code"
                      type="text"
                      v-model="vmodel.promocode"
                    />
                  </div>
                </div>
                <div class="col-3">
                  <PrimeButton
                    label="Apply"
                    class="order-form-button"
                    style="margin-top: 40px"
                    @click="getPromocode"
                  />
                </div>
                <div class="col-3">
                  <div class="field mt-2">
                    <label for="disprice" class="form-label"
                      >Discount Price</label
                    >
                    <div id="disprice" class="disprice-style">
                      {{ vmodel.discount.toFixed(2) }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="grid">
                <div class="col-6">
                  <InputNumberRequire
                    v-model="vmodel.quantity"
                    :validation="v$.vmodel.quantity"
                    sectionClass="field mt-1"
                    id="quantity"
                    label="Quantity"
                    placeholder="Quantity"
                    :min="1"
                  />
                </div>
                <div class="col-6">
                  <InputNumberRequire
                    v-model="vmodel.price"
                    :validation="v$.vmodel.price"
                    placeholder="Price"
                    sectionClass="field mt-1"
                    id="price"
                    :min="1"
                    label="Price"
                  />
                </div>
              </div>
              <div class="grid">
                <div class="col-6">
                  <DropDownRequire
                    v-model="vmodel.shippingmethod"
                    :validation="v$.vmodel.shippingmethod"
                    sectionClass="field mt-1"
                    id="shippingmethod"
                    label="Shipping Method"
                    placeholder="Shipping Method"
                    :options="shippingMethodList"
                    optionLabel="name"
                    optionValue="id"
                    class="dropdown"
                  />
                </div>
                <div class="col-6">
                  <DropDownRequire
                    v-model="vmodel.paymentmethod"
                    :validation="v$.vmodel.paymentmethod"
                    sectionClass="field mt-1"
                    id="paymentmethod"
                    label="Payment Method"
                    placeholder="Payment Method"
                    :options="paymentMethodList"
                    optionLabel="name"
                    optionValue="id"
                    class="dropdown"
                  />
                </div>
              </div>
              <div class="grid">
                <div class="col-6">
                  <DropDownRequire
                    v-model="vmodel.status"
                    :validation="v$.vmodel.status"
                    sectionClass="field mt-1"
                    id="status"
                    label="Status"
                    placeholder="Status"
                    :options="statusList"
                    optionLabel="label"
                    optionValue="value"
                    class="dropdown"
                  />
                </div>
              </div>

              <div class="mt-4">
                <PrimeButton
                  label="Add"
                  class="order-form-button"
                  v-if="!isEditMode"
                  @click="createUpdateOrder"
                ></PrimeButton>
                <PrimeButton
                  label="Edit"
                  class="order-form-button"
                  v-else
                  @click="createUpdateOrder"
                ></PrimeButton>
                <PrimeButton
                  label="Cancel"
                  class="form-cancel-button"
                  @click="onCancel"
                ></PrimeButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <ConfirmPopup
        :show-popup="showPopup"
        @confirmYes="createOrder(newquotationno)"
        @confirmNo="
          showPopup = false;
          newquotationno = '';
        "
        header="Warning"
        :message="`Order already exist for current quotation no. Do you want to proceed with new quotation no ${newquotationno}`"
      ></ConfirmPopup>
    </div>
  </div>
</template>

<script>
// import TemplateImg from "@/assets/images/order-template-img.svg";
import APIService from "@/services/api-service";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  requiredIf,
  email,
  maxLength,
  minLength,
  numeric,
  minValue,
} from "@vuelidate/validators";
import DateFormat from "@/libs/DateFormat";

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      submitted: false,
      showPopup: false,
      currentPage: 0,
      itemsPerPage: 8,
      totalRecords: 0,
      photo: "",
      isEditMode: false,
      title: "Order List / Add",
      printingTypeList: [],
      paperTypeList: [],
      paperColorList: [],
      fontTypeList: [],
      shippingMethodList: [],
      paymentMethodList: [],
      memberList: [],
      newquotationno: "",
      status: "confirmed",
      statusList: [
        { label: "Confirmed", value: "confirmed", sequence: 1 },
        { label: "Processing", value: "processing", sequence: 2 },
        { label: "Transporting", value: "transporting", sequence: 3 },
        { label: "Notified", value: "notified", sequence: 4 },
        { label: "Completed", value: "completed", sequence: 5 },
      ],
      templateList: [
        {
          templateimage: "",
          templatename: "",
          id: "",
        },
      ],
      vmodel: {
        referquotationnumber: "",
        orderno: "",
        orderdate: "",
        userid: "",
        printingtype: "",
        template: "",
        printingmaterial: {
          papertype: "",
          papercolor: "",
          fonttype: "",
          fontcolor: "",
        },
        printoutinformation: {
          companyname: "",
          name: "",
          title: "",
          businessphonenumber: "",
          directline: "",
          mobilenumber: "",
          address: "",
          email: "",
        },
        quantity: 0,
        price: 0,
        promocode: "",
        discount: 0,
        shippingmethod: "",
        paymentmethod: "",
        status: "confirmed",
        uploadfile: "",
        uploadoriginalfile: "",
      },
    };
  },
  validations() {
    return {
      vmodel: {
        referquotationnumber: {
          min: minLength(this.vmodel.referquotationnumber ? 8 : 0),
          max: maxLength(this.vmodel.referquotationnumber ? 8 : 0),
        },
        orderno: { required },
        orderdate: { required },
        userid: { required },
        printingtype: { required },
        printingmaterial: {
          papertype: { required },
          papercolor: { required },
          fonttype: { required },
        },
        printoutinformation: {
          companyname: { required },
          name: { required, max: maxLength(50) },
          title: { required },
          businessphonenumber: {
            required,
            min: minLength(8),
            max: maxLength(8),
            numeric,
          },
          directline: {
            required,
            min: minLength(8),
            max: maxLength(8),
            numeric,
          },
          mobilenumber: {
            required,
            min: minLength(8),
            max: maxLength(8),
            numeric,
          },
          address: { required },
          email: { required, email, max: maxLength(50) },
        },
        uploadfile: {},
        quantity: { required, minValue: minValue(1) },
        price: { required, minValue: minValue(1) },
        shippingmethod: {
          requiredIf: requiredIf(() => {
            return this.vmodel.status !== "confirmed";
          }),
        },
        paymentmethod: {
          requiredIf: requiredIf(() => {
            return this.vmodel.status !== "confirmed";
          }),
        },
        status: { required },
      },
    };
  },
  created() {
    this.formatDate = DateFormat.formatDate;
  },
  mounted() {
    this.getPrintingType();
    this.getMembers();
    this.getPaperType();
    this.getPaperColor();
    this.getFontType();
    this.getShippingMethod();
    this.getPaymentMethod();

    if (this.$route.params.id) {
      this.title = "Order List / Edit";
      this.isEditMode = true;
      this.getOrderById();
    } else {
      this.vmodel.orderno = Math.floor(
        10000000 + Math.random() * 90000000
      ).toString();
      this.disableDropdownOptions();
    }
  },
  methods: {
    onCancel() {
      this.$router.push({ name: "OrderList" });
    },
    openFileUpload() {
      this.$refs.photo.choose();
    },
    disableDropdownOptions() {
      const statusSequence = this.statusList.filter(
        (x) => x.value.toLowerCase() === this.status.toLowerCase()
      );
      if (statusSequence && statusSequence.length > 0) {
        const data = this.statusList.filter(
          (x) => x.sequence >= statusSequence[0].sequence
        );
        this.statusList = data;
      }
    },
    async createUpdateOrder() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        this.submitted = true;
        if (this.$route.params.id) {
          APIService.put("/order", this.vmodel, this.$route.params.id)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "OrderList" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        } else {
          if (this.vmodel.referquotationnumber) {
            APIService.getById(
              "/order/refer/quotationno",
              this.vmodel.referquotationnumber
            ).then((response) => {
              if (response && response.data && response.data.data) {
                if (response.data.data.orderExist) {
                  this.newquotationno = parseInt(
                    response.data.data.newquotationno
                  );
                  this.showPopup = true;
                } else {
                  this.createOrder();
                }
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            });
          } else {
            this.createOrder();
          }
        }
      }
    },
    async createOrder(newquotationno) {
      if (newquotationno) {
        this.vmodel.referquotationnumber = newquotationno;
      }
      APIService.post("/order", this.vmodel)
        .then((response) => {
          if (response && response.data && response.data.success) {
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: response.data.message,
              life: 3000,
            });
            setTimeout(() => {
              this.$router.push({ name: "OrderList" });
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
    async getOrderById() {
      APIService.getById("/order", this.$route.params.id)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.vmodel.referquotationnumber =
              response.data.data.referquotationnumber;
            this.vmodel.orderno = response.data.data.orderno;
            this.vmodel.orderdate = DateFormat.formatDate(
              response.data.data.orderdate
            );
            this.vmodel.userid = response.data.data.userid;
            this.vmodel.printingtype = response.data.data.printingtype;
            this.vmodel.quantity = response.data.data.quantity;
            this.vmodel.price = response.data.data.price;
            this.vmodel.discount = response.data.data.discount;
            this.vmodel.promocode = response.data.data.promocode;
            this.vmodel.shippingmethod = response.data.data.shippingmethod;
            this.vmodel.paymentmethod = response.data.data.paymentmethod;
            this.vmodel.template = response.data.data.template;
            this.vmodel.status = response.data.data.status;
            this.status = response.data.data.status;
            this.vmodel.uploadfile = response.data.data.uploadfile;
            this.vmodel.uploadoriginalfile = response.data.data.uploadoriginalfile;
            this.vmodel.printingmaterial.papertype =
              response.data.data.printingmaterial.papertype;
            this.vmodel.printingmaterial.papercolor =
              response.data.data.printingmaterial.papercolor;
            this.vmodel.printingmaterial.fonttype =
              response.data.data.printingmaterial.fonttype;
            this.vmodel.printingmaterial.fontcolor =
              response.data.data.printingmaterial.fontcolor;
            this.vmodel.printoutinformation.companyname =
              response.data.data.printoutinformation.companyname;
            this.vmodel.printoutinformation.name =
              response.data.data.printoutinformation.name;
            this.vmodel.printoutinformation.title =
              response.data.data.printoutinformation.title;
            this.vmodel.printoutinformation.businessphonenumber =
              response.data.data.printoutinformation.businessphonenumber;
            this.vmodel.printoutinformation.directline =
              response.data.data.printoutinformation.directline;
            this.vmodel.printoutinformation.mobilenumber =
              response.data.data.printoutinformation.mobilenumber;
            this.vmodel.printoutinformation.address =
              response.data.data.printoutinformation.address;
            this.vmodel.printoutinformation.email =
              response.data.data.printoutinformation.email;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
            setTimeout(() => {
              this.$router.push({ name: "OrderList" });
            });
          }
        })
        .finally(async () => {
          this.submitted = false;
          await this.getTemplateByPrintingType(false);
          this.disableDropdownOptions();
        });
    },
    async getQuatation() {
      APIService.getById("/quotation/number", this.vmodel.referquotationnumber)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.vmodel.userid = response.data.data.userid;
            this.vmodel.printingtype = response.data.data.printingtype;
            this.vmodel.template = response.data.data.template;
            this.vmodel.quantity = response.data.data.quantity;
            this.vmodel.price = response.data.data.price;
            this.vmodel.uploadfile = response.data.data.uploadfile;
            this.vmodel.uploadoriginalfile = response.data.data.uploadoriginalfile;
            this.vmodel.printingmaterial.papertype =
              response.data.data.printingmaterial.papertype;
            this.vmodel.printingmaterial.papercolor =
              response.data.data.printingmaterial.papercolor;
            this.vmodel.printingmaterial.fonttype =
              response.data.data.printingmaterial.fonttype;
            this.vmodel.printingmaterial.fontcolor =
              response.data.data.printingmaterial.fontcolor;
            this.vmodel.printoutinformation.companyname =
              response.data.data.printoutinformation.companyname;
            this.vmodel.printoutinformation.name =
              response.data.data.printoutinformation.name;
            this.vmodel.printoutinformation.title =
              response.data.data.printoutinformation.title;
            this.vmodel.printoutinformation.businessphonenumber =
              response.data.data.printoutinformation.businessphonenumber;
            this.vmodel.printoutinformation.directline =
              response.data.data.printoutinformation.directline;
            this.vmodel.printoutinformation.mobilenumber =
              response.data.data.printoutinformation.mobilenumber;
            this.vmodel.printoutinformation.address =
              response.data.data.printoutinformation.address;
            this.vmodel.printoutinformation.email =
              response.data.data.printoutinformation.email;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(async () => {
          this.submitted = false;
          await this.getTemplateByPrintingType(false);
        });
    },
    setTemplateId(id) {
      this.vmodel.template = id;
    },
    getTextPath(name) {
      return process.env.VUE_APP_ORDER_TEXT_BASE_PATH + name;
    },
    onAdvancedUpload(fileObj) {
      if (
        !fileObj ||
        (fileObj && !fileObj.files) ||
        (fileObj && fileObj.files && fileObj.files.length === 0)
      ) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Please select valid Text format",
          life: 3000,
        });
        this.$refs.photo.clear();
      } else if (fileObj && fileObj.files && fileObj.files[0].size > 1000000) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Image size should be less then 1 MB",
          life: 3000,
        });
        this.$refs.photo.clear();
      } else {
        let formData = new FormData();
        formData.append("file", fileObj.files[0]);
        APIService.post("/order/contentfile", formData, null, true)
          .then((response) => {
            if (response && response.data && response.data.success) {
              this.vmodel.uploadfile = response.data.data.name;
              this.vmodel.uploadoriginalfile = response.data.data.originalname;
              this.$toast.add({
                severity: "success",
                summary: "Success",
                detail: response.data.message,
                life: 3000,
              });
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: response.data.message,
                life: 3000,
              });
            }
          })
          .finally(() => {
            this.submitted = false;
            this.$refs.photo.clear();
          });
      }
    },
    getImagePath(name) {
      return process.env.VUE_APP_TEMPLATE_IMAGE_BASE_PATH + name;
    },
    async getTemplateByPrintingType(resetTemplate = false) {
      APIService.get(
        `/template/temp/${this.currentPage}/${this.itemsPerPage}/${this.vmodel.printingtype}`
      ).then((response) => {
        if (response && response.data) {
          this.totalRecords = response.data.totalRecords;
          this.templateList = response.data.data;
          if (resetTemplate) {
            this.vmodel.template = "";
          }
        }
      });
    },
    onPageChange(pageData) {
      this.currentPage = pageData.first;
      this.getTemplateByPrintingType();
    },
    getPrintingType() {
      APIService.get("/dropdownlist/dropdown/printingtype")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.printingTypeList = response.data.data;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
    getPaperType() {
      APIService.get("/dropdownlist/dropdown/papertype")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.paperTypeList = response.data.data;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
    getPaperColor() {
      APIService.get("/dropdownlist/dropdown/papercolor")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.paperColorList = response.data.data;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
    getFontType() {
      APIService.get("/dropdownlist/dropdown/fonttype")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.fontTypeList = response.data.data;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
    getShippingMethod() {
      APIService.get("/dropdownlist/dropdown/shippingmethod")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.shippingMethodList = response.data.data;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
    getPaymentMethod() {
      APIService.get("/dropdownlist/dropdown/paymentmethod")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.paymentMethodList = response.data.data;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
    getMembers() {
      APIService.get("/members/dropdown")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.memberList = response.data.data;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
    getPromocode() {
      let payload = {
        code: this.vmodel.promocode,
        price: this.vmodel.price,
      };
      APIService.post("/promontioncode/bycode/", payload)
        .then((response) => {
          if (response && response.data && response.data.data) {
            const discountPrice = response.data.data.discountprice * -1;
            if (discountPrice > this.vmodel.price) {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: "Discount price could not be higher than total price.",
                life: 3000,
              });
            } else {
              let totalPrice = this.vmodel.price
              if(this.vmodel.discount < 0) {
                totalPrice = totalPrice - this.vmodel.discount;
              }
              setTimeout(() => {
                this.vmodel.discount = response.data.data.discountprice;
                this.vmodel.price = totalPrice - discountPrice;  
              }, 100);
            }
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.disable-text-field) {
  .p-inputtext {
    background: rgba(27, 27, 27, 0.15);
    border: 1px solid rgba(27, 27, 27, 0.4);
    color: rgba(27, 27, 27, 0.8);
  }
}
::v-deep(.dropdown) {
  .p-inputtext:hover {
    border: none !important;
  }
}
.product-item {
  .product-item-content {
    border: 1px solid var(--surface-border);
    border-radius: 3px;
    margin: 0.3rem;
    text-align: center;
    padding: 2rem 0;
  }

  .product-image {
    width: 50%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
}

.header-text {
  height: 24px;
  font-family: OpenSans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-transform: capitalize;
  color: #1b1b1b;
}

.optional-header-text {
  height: 20px;
  font-family: OpenSans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: rgba(27, 27, 27, 0.4);
}

.photo-section {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 12px;
  gap: 4px;
  /* width: 720px; */
  height: 120px;
  background: #ffffff;
  border: 1px dashed rgba(27, 27, 27, 0.4);
  border-radius: 4px;
}

.upload-text {
  width: 156px;
  height: 20px;
  font-family: OpenSans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #1b1b1b;
}

.upload-text-small {
  width: 66px;
  height: 20px;
  font-family: OpenSans;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: rgba(27, 27, 27, 0.4);
}

::v-deep(.color-field) {
  .p-inputtext {
    width: 40px;
    height: 40px;
    background: #1b1b1b;
    border-radius: 4px;
  }
}

::v-deep(.paginator) {
  .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: #ee3a3a;
    border-color: #ee3a3a;
    color: #ffffff;
  }
  .p-paginator .p-paginator-pages .p-paginator-page {
    background: linear-gradient(
        0deg,
        rgba(27, 27, 27, 0.05),
        rgba(27, 27, 27, 0.05)
      ),
      #ffffff;
    border: none;
    color: #1b1b1b;
    min-width: 2.357rem;
    height: 2.357rem;
    margin: 4px;
    transition: box-shadow 0.15s;
    border-radius: 2px;
  }
  .p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
    background: linear-gradient(
        0deg,
        rgba(27, 27, 27, 0.05),
        rgba(27, 27, 27, 0.05)
      ),
      #ffffff;
    border-color: none;
    color: #1b1b1b;
  }
}

.card-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  gap: 8px;
  width: 171px;
  height: 199px;
  background: #f0f0f0;
  border-radius: 4px;
}

.img-name-text {
  height: 20px;
  font-family: OpenSans;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #1b1b1b;
  margin-top: -3px;
}

.font-color-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px 8px 8px;
  gap: 8px;
  width: 136px;
  height: 56px;
  background: #f0f0f0;
  border-radius: 4px;
  margin-top: 13px;
  margin-left: 5px;
}

.template-selected {
  border: 2px solid #1b1b1b;
}

.disprice-style {
  margin-top: 10px;
  font-family: "OpenSans";
  font-weight: 500px;
  font-size: 19px;
  font-style: normal;
  color: #ee3b3b;
  margin-left: 34px;
}

.margin-color {
  margin-left: 4px;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 150px;
  display: inline-block;
}

.upload-text-style {
  font-family: OpenSans;
  font-style: normal;
  font-weight: 400px;
  font-size: 18px;
  line-height: 30px;
  color: #ee3b3b;
}
</style>