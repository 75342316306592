import ShippingMethod from '@/models/shippingmethod'

export default class ShippingMethodFactory {
    static createFromJson(json) {
        return new ShippingMethod(json)
    }

    static createFromJsonArray(jsonArray) {
        const jsonData = []
        jsonArray.forEach(item => {
            jsonData.push(ShippingMethodFactory.createFromJson(item))
        })

        return jsonData
    }
}
