<template>
  <div class="sidebar">
    <div class="logo">
      <span><img src="../../assets/icons/logo.svg" /></span>
    </div>
    <div class="menu mt-6">
      <router-link
        :to="{ name: 'MemberManagement' }"
        id="MemberManagement"
        :class="
          $store.state.activeRoute === 'MemberManagement'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
        <i class="member-icon"></i>
        <span class="menu-text">Member Management</span>
      </router-link>

       <router-link
        :to="{ name: 'QuotationList' }"
        id="QuotationList"
        :class="
          $store.state.activeRoute === 'QuotationList'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
        <i class="quota-icon"></i>
        <span class="menu-text">Quotation List</span>
      </router-link>
        
        <router-link
        :to="{ name: 'OrderList' }"
        id="OrderList"
        :class="
          $store.state.activeRoute === 'OrderList'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
        <i class="order-icon"></i>
        <span class="menu-text">Order List</span>
      </router-link>

      <router-link
        :to="{ name: 'TemplateList' }"
        id="TemplateList"
        :class="
          $store.state.activeRoute === 'TemplateList'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
        <i class="template-icon"></i>
        <span class="menu-text">Template</span>
      </router-link>

      <router-link
        :to="{ name: 'PromotionCodeList' }"
        id="PromotionCodeList"
        :class="
          $store.state.activeRoute === 'PromotionCodeList'
            ? 'menu-item selected'
            : 'menu-item'
        "
      >
        <i class="promotion-icon"></i>
        <span class="menu-text">Promotion Code Management</span>
      </router-link>
     
      <div
        class="menu-item cursor-pointer"
        @click="visibleCMSMenu = !visibleCMSMenu"
      >
        <i class="cms-icon"></i>
        <span class="menu-text">CMS Page Management</span>
        <i
          class="margin"
          :class="{
            ' chevron-down-icon': !visibleCMSMenu,
            ' chevron-up-icon': visibleCMSMenu,
          }"
        ></i>
      </div>
      <router-link
        v-if="visibleCMSMenu"
        :to="{ name: 'PrivacyPolicy' }"
        id="PrivacyPolicy"
        :class="
          $store.state.activeRoute === 'PrivacyPolicy'
            ? 'menu-item selected'
            : 'menu-item'
        "
        class="dropdown-list-padding"
      >
        <span class="menu-text">Privacy Policy Page</span>
      </router-link>

      <router-link
        v-if="visibleCMSMenu"
        :to="{ name: 'TermsAndConditions' }"
        id="TermsAndConditions"
        :class="
          $store.state.activeRoute === 'TermsAndConditions'
            ? 'menu-item selected'
            : 'menu-item'
        "
        class="dropdown-list-padding"
      >
        <span class="menu-text">Terms & Conditions Page</span>
      </router-link>

      <div
        class="menu-item cursor-pointer"
        @click="visibleDDMenu = !visibleDDMenu"
      >
        <i class="dropdown-icon"></i>
        <span class="menu-text">Drop Down List Management</span>
        <i
          :class="{
            ' chevron-down-icon': !visibleDDMenu,
            ' chevron-up-icon': visibleDDMenu,
          }"
        ></i>
      </div>

      <router-link
        v-if="visibleDDMenu"
        :to="{ name: 'PrintingType' }"
        id="PrintingType"
        :class="
          $store.state.activeRoute === 'PrintingType'
            ? 'menu-item selected'
            : 'menu-item'
        "
        class="dropdown-list-padding"
      >
        <span class="menu-text">Printing Type</span>
      </router-link>

      <router-link
        v-if="visibleDDMenu"
        :to="{ name: 'PaperType' }"
        id="PaperType"
        :class="
          $store.state.activeRoute === 'PaperType'
            ? 'menu-item selected'
            : 'menu-item'
        "
        class="dropdown-list-padding"
      >
        <span class="menu-text">Paper Type</span>
      </router-link>

      <router-link
        v-if="visibleDDMenu"
        :to="{ name: 'PaperColor' }"
        id="PaperColor"
        :class="
          $store.state.activeRoute === 'PaperColor'
            ? 'menu-item selected'
            : 'menu-item'
        "
        class="dropdown-list-padding"
      >
      <span class="menu-text">Paper Color</span>
      </router-link>

      <router-link
        v-if="visibleDDMenu"
        :to="{ name: 'FontType' }"
        id="FontType"
        :class="
          $store.state.activeRoute === 'FontType'
            ? 'menu-item selected'
            : 'menu-item'
        "
        class="dropdown-list-padding"
      >
        <span class="menu-text">Font Type</span>
      </router-link>

      <router-link
        v-if="visibleDDMenu"
        :to="{ name: 'ShippingMethod' }"
        id="ShippingMethod"
        :class="
          $store.state.activeRoute === 'ShippingMethod'
            ? 'menu-item selected'
            : 'menu-item'
        "
        class="dropdown-list-padding"
      >
        <span class="menu-text">Shipping Method</span>
      </router-link>

      <router-link
        v-if="visibleDDMenu"
        :to="{ name: 'PaymentMethod' }"
        id="PaymentMethod"
        :class="
          $store.state.activeRoute === 'PaymentMethod'
            ? 'menu-item selected'
            : 'menu-item'
        "
        class="dropdown-list-padding"
      >
        <span class="menu-text">Payment Method</span>
      </router-link>

    </div>
  </div>
</template>

<script>
export default {
  name: "SidebarMenu",
  data() {
    return {
      // username: "chantaiman12345",
      visibleLeft: true,
      visibleCMSMenu: false,
      visibleDDMenu: false,

    };
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
      this.$router.push({ name: "Login" });
    },
  },
};
</script>

<style scoped>
@import "@/assets/css/sidebar.css";

.dropdown-list-padding {
  padding-left: 57px;
}

.margin {
  margin-left: 38px !important;
}
</style>