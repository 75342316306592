<template>
  <div class="ml-8 mr-8 margin-top mb-5">
    <div class="grid">
      <div class="col-12 order-form-title">{{ title }}</div>
      <div class="order-card w-full">
        <div class="col-12">
          <div class="grid">
            <div class="col-4">
              <div class="order-form-sub-title">Member Detail</div>
            </div>
            <div class="col-8">
              <div class="grid">
                <div class="col-6">
                  <InputTextRequire
                    v-model="vmodel.userid"
                    :validation="v$.vmodel.userid"
                    sectionClass="field mt-1"
                    id="userid"
                    label="User ID (Auto Generate)"
                    :disabled="true"
                    class="disable-text-field"
                  />
                </div>
              </div>
              <div class="grid">
                <div class="col-2">
                  <label for="span" class="form-label">Avatar</label>
                  <span id="span"
                    ><img
                      :src="getAvatarPath(vmodel.avatar)"
                      alt=""
                      class="avatar-img mt-1"
                  /></span>
                </div>
                <FileUpload
                  ref="photo"
                  name="photo"
                  accept=".png, .jpg, .jpeg"
                  @select="onAdvancedUpload($event)"
                  v-show="false"
                  :aria-describedby="`photo-help`"
                />
                <div class="col-3">
                  <PrimeButton
                    label="Upload"
                    class="upload-button"
                    @click="openFileUpload"
                  />
                </div>
              </div>
              <div class="grid">
                <div class="col-6">
                  <InputTextRequire
                    v-model="vmodel.username"
                   :validation="v$.vmodel.username"
                    sectionClass="field mt-1"
                    id="username"
                    label="Username"
                    placeholder="Username"
                    maxlength="50"
                  />
                </div>
                <div class="col-6">
                  <InputTextRequire
                    v-model="vmodel.email"
                    :validation="v$.vmodel.email"
                    sectionClass="field mt-1"
                    id="email"
                    label="Email"
                    placeholder="Email"
                    maxlength="50"
                  />
                </div>
              </div>
              <div class="grid">
                <div class="col-6">
                  <InputTextRequire
                    v-model="vmodel.phoneno"
                   :validation="v$.vmodel.phoneno"
                    sectionClass="field mt-1"
                    id="phoneno"
                    label="Phone"
                    placeholder="Phone"
                    maxlength="8"
                    minlength="8"
                  />
                </div>
              </div>
              <InputTextRequire
                v-model="vmodel.address"
                :validation="v$.vmodel.address"
                sectionClass="field mt-1"
                id="address"
                label="Address"
                placeholder="Address"
              />
              <div class="mt-4">
                <PrimeButton
                  label="Add"
                  class="order-form-button"
                  v-if="!isEditMode"
                  @click="createMember"
                ></PrimeButton>
                <PrimeButton
                  label="Edit"
                  class="order-form-button"
                  v-else
                  @click="createMember"
                ></PrimeButton>
                <PrimeButton
                  label="Cancel"
                  class="form-cancel-button"
                  @click="onCancel"
                ></PrimeButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import APIService from "@/services/api-service.js";
import {useVuelidate} from "@vuelidate/core";
import {
  required,
  email,
  maxLength,
  minLength,
  numeric,
} from "@vuelidate/validators";
export default {
  setup(){
    return {v$:useVuelidate()};
  },
  data() {
    return {
      photo: "",
      submitted: false,
      isEditMode: false,
      title: "Member Management / Add",
      vmodel: {
        userid: "",
        avatar: "avatar-default.png",
        username: "",
        email: "",
        phoneno: "",
        address: "",
      },
    };
  },
  validations(){
    return{
      vmodel:{
        userid:{},
        avatar: {},
        username:{required,max:maxLength(50)},
        email:{required,email,max:maxLength(50)},
        phoneno:{required,min:minLength(8),max:maxLength(8),numeric},
        address:{required},
      }
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.title = "Member Management / Edit";
      this.isEditMode = true;
      this.getMemberById();
    } else {
      this.vmodel.userid = Math.floor(
        10000000 + Math.random() * 90000000
      ).toString();
    }
  },
  methods: {
    openFileUpload() {
      this.$refs.photo.choose();
    },
    onCancel() {
      this.$router.push({ name: "MemberManagement" });
    },
    async createMember() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
      let payload = {
        userid: this.vmodel.userid,
        avatar: this.vmodel.avatar,
        username: this.vmodel.username,
        email: this.vmodel.email,
        phoneno: this.vmodel.phoneno,
        address: this.vmodel.address,
      };
      this.submitted = true;
      if (this.$route.params.id) {
        APIService.put("/members", payload, this.$route.params.id)
          .then((response) => {
            if (response && response.data && response.data.success) {
              this.$toast.add({
                severity: "success",
                summary: "Success",
                detail: response.data.message,
                life: 3000,
              });
              setTimeout(() => {
                this.$router.push({ name: "MemberManagement" });
              });
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: response.data.message,
                life: 3000,
              });
            }
          })
          .finally(() => {
            this.submitted = false;
          });
      } else {
        APIService.post("/members", payload)
          .then((response) => {
            if (response && response.data && response.data.success) {
              this.$toast.add({
                severity: "success",
                summary: "Success",
                detail: response.data.message,
                life: 3000,
              });
              setTimeout(() => {
                this.$router.push({ name: "MemberManagement" });
              });
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: response.data.message,
                life: 3000,
              });
            }
          })
          .finally(() => {
            this.submitted = false;
          });
      }
      }
    },

    async getMemberById() {
      APIService.getById("/members", this.$route.params.id)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.vmodel.userid = response.data.data.userid;
            this.vmodel.avatar = response.data.data.avatar;
            this.vmodel.username = response.data.data.username;
            this.vmodel.email = response.data.data.email;
            this.vmodel.phoneno = response.data.data.phoneno;
            this.vmodel.address = response.data.data.address;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
            setTimeout(() => {
              this.$router.push({ name: "MemberManagement" });
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
    getAvatarPath(avatarname) {
      return process.env.VUE_APP_PROFILE_IMAGE_BASE_PATH + avatarname;
    },
    onAdvancedUpload(fileObj) {
      if (
        !fileObj ||
        (fileObj && !fileObj.files) ||
        (fileObj && fileObj.files && fileObj.files.length === 0)
      ) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Please select valid avatar",
          life: 3000,
        });
        this.$refs.photo.clear();
      } else if (fileObj && fileObj.files && fileObj.files[0].size > 1000000) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Image size should be less then 1 MB",
          life: 3000,
        });
        this.$refs.photo.clear();
      } else {
        let formData = new FormData();
        formData.append("image", fileObj.files[0]);
        APIService.post("/members/avatar", formData, null, true)
          .then((response) => {
            if (response && response.data && response.data.success) {
              // console.log(response.data, 'response.data.data.name');
              this.vmodel.avatar = response.data.data.name;
              this.$toast.add({
                severity: "success",
                summary: "Success",
                detail: response.data.message,
                life: 3000,
              });
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: response.data.message,
                life: 3000,
              });
            }
          })
          .finally(() => {
            this.submitted = false;
            this.$refs.photo.clear();
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-img {
  height: 80px;
  width: 80px;
  border-radius: 80px;
}

::v-deep(.disable-text-field) {
  .p-inputtext {
    background: rgba(27, 27, 27, 0.15);
    border: 1px solid rgba(27, 27, 27, 0.4);
    color: rgba(27, 27, 27, 0.8);
  }
}
</style>