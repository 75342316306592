import DateFormat from "@/libs/DateFormat"

export default class PaperType {
    constructor(data = {}) {
        return {
            id: data.id || '',
            name: data.name || '',
            createdby: data.createdby || '',
            createddate: DateFormat.formatDateTime(data.createddate) || '',
        }
    }
}