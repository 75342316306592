<template>
  <div class="ml-8 mr-8 margin-top mb-5">
    <div class="grid">
      <div class="col-12 order-form-title">{{ title }}</div>
      <div class="order-card w-full">
        <div class="col-12">
          <div class="grid">
            <div class="col-4">
              <div class="order-form-sub-title">Font Type Detail</div>
            </div>
            <div class="col-8">
              <InputTextRequire
                v-model="vmodel.fonttype"
                :validation="v$.vmodel.fonttype"
                sectionClass="field mt-1"
                id="fonttype"
                label="Font Type"
                placeholder="Font Type"
              />
              <div class="mt-4">
                <PrimeButton
                  label="Add"
                  class="order-form-button"
                  v-if="!isEditMode"
                  @click="createMember"
                ></PrimeButton>
                <PrimeButton
                  label="Edit"
                  class="order-form-button"
                  v-else
                  @click="createMember"
                ></PrimeButton>
                <PrimeButton
                  label="Cancel"
                  class="form-cancel-button"
                  @click="onCancel"
                ></PrimeButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import APIService from "@/services/api-service.js" 
import {useVuelidate} from "@vuelidate/core";
import {
  required,
} from "@vuelidate/validators";
export default {
  setup(){
    return {v$:useVuelidate()};
  },
  data() {
    return {
      isEditMode: false,
      title: "Drop Down List Management / Font Type / Add",
      vmodel: {
        fonttype: '',
      },
    };
  },
   validations(){
    return{
      vmodel:{
        fonttype:{required},
      }
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.title = "Drop Down List Management / Font Type / Edit";
      this.isEditMode = true;
      this.getFontTypeById()
    }
  },  
  methods: {
    onCancel() {
        this.$router.push({ name: "FontType" });
    },
        async createMember() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
      let payload = {
        type: 'fonttype',
        name: this.vmodel.fonttype
      };
      // this.submitted = true;
      if (this.$route.params.id) {
        APIService.put("/dropdownlist", payload, this.$route.params.id)
          .then((response) => {
            if (response && response.data && response.data.success) {
              this.$toast.add({
                severity: "success",
                summary: "Success",
                detail: response.data.message,
                life: 3000,
              });
              setTimeout(() => {
                this.$router.push({ name: "FontType" });
              });
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: response.data.message,
                life: 3000,
              });
            }
          })
          .finally(() => {
            this.submitted = false;
          });
      } else {
        APIService.post("/dropdownlist/", payload)
          .then((response) => {
            if (response && response.data && response.data.success) {
              this.$toast.add({
                severity: "success",
                summary: "Success",
                detail: response.data.message,
                life: 3000,
              });
              setTimeout(() => {
                this.$router.push({ name: "FontType" });
              });
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: response.data.message,
                life: 3000,
              });
            }
          })
          .finally(() => {
            this.submitted = false;
          });
      }
      }
    },
    async getFontTypeById() {
      APIService.getById("/dropdownlist", this.$route.params.id)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.vmodel.fonttype = response.data.data.name
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
            setTimeout(() => {
              this.$router.push({ name: "FontType" });
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
  },
};
</script>