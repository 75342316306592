<template>
  <div class="list-margin">
    <DataTable
      :value="promotioncode"
      :paginator="true"
      :lazy="true"
      class="p-datatable-lg p-datatable-customers"
      :rows="itemsPerPage"
      :totalRecords="totalRecords"
      dataKey="code"
      :rowHover="true"
      :resizableColumns="true"
      @page="onPage($event)"
      @sort="onSort($event)"
      paginatorTemplate="PrevPageLink PageLinks NextPageLink CurrentPageReport"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} results"
    >
      <template #header>
        <div class="flex">
          <div class="col-6 text-left list-header">Promotion Code Management</div>
          <div class="col-6 text-right ml-4">
            <PrimeButton
              label="Add Promotion Code"
              class="add-button mr-3"
              icon="plus-icon"
              @click="gotoCreatePromoCode"
            ></PrimeButton>
            <div class="p-input-icon-right">
              <i class="search-icon cursor-pointer" @click="onSearch" />
              <InputText
                v-model="search"
                placeholder="search"
                class="search-input"
              />
            </div>
          </div>
        </div>
      </template>
      <template #loading> Loading records, please wait... </template>
      <template #empty> No records found. </template>
      <Column field="code" header="Promotion Code" sortable="true"></Column>
      <Column field="discounttype" header="Discount Type" sortable="true"></Column>
      <Column field="discount" header="Discount" sortable="true"></Column>
      <Column field="description" header="Description" sortable="true"></Column>
      <Column field="createdby" header="Created By" sortable="true" ></Column>
      <Column field="createddate" header="Created At" sortable="true"></Column>
      <Column field="actions" header="Actions" >
        <template #body="data">
          <div class="flex">
            <PrimeButton label="Edit" class="edit-button ml-2" @click="gotoPromoCodeEdit(data.data.id)" />
            <PrimeButton label="Delete" class="delete-button ml-2" @click="showDeletePopup(data.data.id)" />
          </div>
        </template>
      </Column>
    </DataTable>
     <div>
      <ConfirmPopup
        :show-popup="showPopup"
        @confirmYes="deletePromotioncode"
        @confirmNo="showPopup = false"
      ></ConfirmPopup>
    </div>
  </div>
</template>

<script>
import APIService from "@/services/api-service.js";
import PromotioncodeFactory from "@/factories/promotioncode.js";
export default {
  data() {
    return {
      currentPage: 0,
      itemsPerPage: 10,
      search: "",
      totalRecords: 0,
      sortColumn: "id",
      sortDir: "asc",
      promotioncodeIdForDelete: "",
      showPopup: false,
      promotioncode: [],
    };
  },
  mounted() {
    this.getCodeList();
  //  this.getPromotioncodeList();
  },
  methods: {
    gotoCreatePromoCode() {
      this.$router.push({ name: "PromotionCodeListCreate" });
    },
    gotoPromoCodeEdit(promotioncodeId) {
      this.$router.push({
        name: "PromotionCodeListEdit",
        params: { id: promotioncodeId},
      });
    },
    getCodeList() {
      this.promotioncode = [];
      APIService.get(
        `/promontioncode/${this.currentPage}/${this.itemsPerPage}/${this.sortColumn}/${this.sortDir}/${this.search}`
      ).then((response) => {
        if (response && response.data) {
          this.totalRecords = response.data.totalrecord;
          this.promotioncode = PromotioncodeFactory.createFromJsonArray(
            response.data.data
          );
        }
      });
    },
      showDeletePopup(promotioncodeId) {
      this.promotioncodeIdForDelete = promotioncodeId;
      this.showPopup = true;
    },
    deletePromotioncode() {
      APIService.delete(`/promontioncode/delete/${this.promotioncodeIdForDelete}`)
        .then((response) => {
          if (response && response.data && response.data.success) {
            this.getCodeList();
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.showPopup = false;
        });
    },
    onPage(pageData) {
      this.currentPage = pageData.first;
      this.getCodeList();
    },
    onSort(sortData) {
      let sortField = sortData.sortField;
      if (sortData.sortField === "createdby") {
        sortField = "createdby";
      } else if (sortData.sortField === "createddate") {
        sortField = "createddate";
      }
      this.sortColumn = sortField;
      this.sortDir = sortData.sortOrder === 1 ? "asc" : "dsc";
      this.currentPage = 0;

      this.getCodeList();
    },
    onSearch() {
      this.search = this.search.trimStart().trimEnd();
      this.currentPage = 0;
      this.getCodeList();
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/datatable.scss";
</style>