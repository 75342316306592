<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="container-top">
    <span class="navbar-text">Tin Hung Scan Centre</span>
    <div class="flex" style="margin-left: 196px">
      <div class="profile-name-style">
        <span class="navbar-profile-text">
          {{
              $store.state.userInfo.username
          }}
        </span>
      </div>
    </div>
    <div class="grid" style="margin-left: -361px">
      <div class="col-8">
        <span class=""
          ><img
            src="../../assets/images/profile-photo.png"
            alt=""
            class="img-style margin-img"
        /></span>
      </div>
      <div class="col-4">
        <span v-click-outside="hide"
          ><i
            class="arrow-icon cursor-pointer margin-arrow"
            @click="visibleDDMenu = !visibleDDMenu"
          ></i
        ></span>
      </div>
    </div>
  </div>
  <div v-if="visibleDDMenu" class="dropdown">
    <div class="grid">
      <div
      style="margin-top:17px"
        class="col-12 ml-5 profile-setting-text cursor-pointer"
        @click="goToProfilePage"
      >
        Profile Setting
      </div>
    </div>
    <div class="grid">
      <hr class="line" />
    </div>
    <div class="grid">
      <div
      style="margin-top:10px"
        class="col-12 ml-5 profile-setting-text cursor-pointer"
        @click="logout"
      >
        Logout
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visibleDDMenu: false,
    };
  },
  methods: {
    hide(){
      this.visibleDDMenu = false;
    },
    goToProfilePage() {
      this.$router.push({ name: "ProfileSetting" });
    },
    logout() {
      this.$store.dispatch("logout");
      this.$router.push({ name: "Login" });
    },
  },
};
</script>

<style scoped>
.profile-name-style {
  margin-top: 4px;
  /* text-align: right; */
}

.profile-role-style {
  margin-top: -16px;
  text-align: right;
}

.margin-arrow {
  /* margin-right: -54px;
  margin-top: 16px; */
  margin-left: -32px;
  margin-top: -57px;
}

.margin-img {
  /* margin-right: -10px;
  margin-top: 7px; */
  margin-left: -90px;
  margin-top: 34px;
}

.img-style {
  box-sizing: border-box;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  filter: drop-shadow(0px 2px 8px rgba(187, 187, 187, 0.36));
}

.dropdown {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  position: absolute;
  width: 240px;
  height: 112px;
  left: 1640px;
  top: 72px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(187, 187, 187, 0.25);
  border-radius: 4px;
  margin-left: -376px;
  z-index: 9999;
}

.profile-setting-text {
  width: 241px;
  height: 35px;

  font-family: OpenSans;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  color: #1b1b1b;
}
.ml-5 {
  margin-left: 1.3rem !important;
}

.line {
  margin-left: 7px;
  width: 241px;
  border-bottom: 1px solid rgba(27, 27, 27, 0.15);
  margin-top: 18px;
}

.profile {
  margin-top: 18px;
  margin-left: 6px;
}

.logout {
  margin-top: 10px;
  margin-left: 6px;
}
</style>