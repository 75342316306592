import DateFormat from "@/libs/DateFormat"

export default class Template {
    constructor(data = {}) {
        return {
            id: data.id || '',
            templateimage: data.templateimage || '',
            templatename: data.templatename || '',
            printingtype: data.printingtype || '',
            createdby: data.createdby || '',
            createddate: DateFormat.formatDateTime(data.createddate) || '',
        }
    }
}