<template>
  <div class="ml-8 mr-8 margin-top mb-5">
    <div class="grid">
      <div class="col-12 order-form-title">{{ title }}</div>
      <div class="order-card w-full">
        <div class="col-12">
          <div class="grid">
            <div class="col-3">
              <div class="order-form-sub-title">Promotion Code Detail</div>
            </div>
            <div class="col-9">
              <div class="grid">
                <div class="col-4">
                  <InputTextRequire
                    v-model="vmodel.code"
                    :validation="v$.vmodel.code"
                    sectionClass="field mt-1"
                    id="promotioncode"
                    label="Promotion Code"
                    placeholder="Promotion Code"
                  />
                </div>
                <div class="col-4">
                  <DropDownRequire
                    v-model="vmodel.discounttype"
                    :validation="v$.vmodel.discounttype"
                    sectionClass="field mt-1"
                    id="discounttype"
                    label="Discount Type"
                    placeholder="Select Discount Type"
                    :options="discountTypeList"
                    optionLabel="label"
                    optionValue="value"
                    class="dropdown"
                  />
                </div>
                <div class="col-4">
                  <InputGroup
                    v-model="vmodel.discount"
                    :validation="v$.vmodel.discount"
                    placeholder="Discount"
                    sectionClass="field mt-1"
                    id="discount"
                    label="Discount"
                    :min="1"
                    v-if="vmodel.discounttype == 'Fixed'"
                  />
                  <InputPercent
                    v-model="vmodel.discount"
                    :validation="v$.vmodel.discount"
                    placeholder="Discount"
                    sectionClass="field mt-1"
                    id="discount"
                    label="Discount"
                    v-else
                  />
                </div>
              </div>
              <InputTextRequire
                v-model="vmodel.description"
                :validation="v$.vmodel.description"
                sectionClass="field mt-1"
                id="description"
                label="Description"
                placeholder="Description"
              />
              <div class="mt-4">
                <PrimeButton
                  label="Add"
                  class="order-form-button"
                  v-if="!isEditMode"
                  @click="createMember"
                ></PrimeButton>
                <PrimeButton
                  label="Edit"
                  class="order-form-button"
                  v-else
                  @click="createMember"
                ></PrimeButton>
                <PrimeButton
                  label="Cancel"
                  class="form-cancel-button"
                  @click="onCancel"
                ></PrimeButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import APIService from "@/services/api-service.js";
import { useVuelidate } from "@vuelidate/core";
import {
  required,
  minValue,
  maxValue,
  minLength,
  maxLength,
} from "@vuelidate/validators";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      photo: "",
      isEditMode: false,
      title: "Promotion Code Management / Add",
      discountTypeList: [
        { label: "Fixed", value: "Fixed" },
        { label: "Percentage", value: "Percentage" },
      ],
      vmodel: {
        code: "",
        description: "",
        discounttype: "Fixed",
        discount: 1,
      },
    };
  },
  validations() {
    return {
      vmodel: {
        code: { required, min: minLength(3), max: maxLength(15) },
        description: { required },
        discounttype: { required },
        discount: {
          required,
          minValue: minValue(1),
          maxValue: maxValue(this.discountMaxValue),
        },
      },
    };
  },
  computed: {
    discountMaxValue() {
      return this.vmodel.discounttype === "Percentage" ? 100 : 10000000
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.title = "Promotion Code Management / Edit";
      this.isEditMode = true;
      this.getPromotioncodeById();
    }
  },
  methods: {
    onCancel() {
      this.$router.push({ name: "PromotionCodeList" });
    },
    async createMember() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        let payload = {
          code: this.vmodel.code,
          description: this.vmodel.description,
          discounttype: this.vmodel.discounttype,
          discount: this.vmodel.discount,
        };
        this.submitted = true;
        if (this.$route.params.id) {
          APIService.put("/promontioncode", payload, this.$route.params.id)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "PromotionCodeList" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        } else {
          APIService.post("/promontioncode", payload)
            .then((response) => {
              if (response && response.data && response.data.success) {
                this.$toast.add({
                  severity: "success",
                  summary: "Success",
                  detail: response.data.message,
                  life: 3000,
                });
                setTimeout(() => {
                  this.$router.push({ name: "PromotionCodeList" });
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Error",
                  detail: response.data.message,
                  life: 3000,
                });
              }
            })
            .finally(() => {
              this.submitted = false;
            });
        }
      }
    },

    async getPromotioncodeById() {
      APIService.getById("/promontioncode", this.$route.params.id)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.vmodel.code = response.data.data.code;
            this.vmodel.description = response.data.data.description;
            this.vmodel.discounttype = response.data.data.discounttype;
            this.vmodel.discount = response.data.data.discount;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
            setTimeout(() => {
              this.$router.push({ name: "PromotionCodeList" });
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.dropdown) {
  .p-inputtext:hover {
    border: none !important;
  }
}
</style>