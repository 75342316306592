export default class PrintingMaterialForOrder {
    constructor(data = {}) {
        return {
          papertype: data.papertype || '',
          papercolor: data.papercolor || '',
          fonttype: data.fonttype || '',
          fontcolor: data.fontcolor || '',
        }

    }
}