export default class PrintingOutInfo {
    constructor(data = {}) {
        return {
          companyname: data.companyname || '',
          name: data.name || '',
          title: data.title || '',
          businessphonenumber: data.businessphonenumber || '',
          directline: data.directline || '',
          mobilenumber: data.mobilenumber || '',
          address: data.address || '',
          email: data.email || '',
        }

    }
}