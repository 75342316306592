<template>
  <div class="profile-header-text">Profile Setting</div>
  <div class="profile-setting-box">
    <div class="col-12">
      <div class="grid">
        <div class="col-4">
          <div class="profile-sub-title">Change Password</div>
        </div>
        <div class="col-8">
          <div class="grid">
            <div class="col-6">
              <InputTextRequire
                v-model="vmodel.password"
                :validation="v$.vmodel.password"
                sectionClass="field mt-1"
                id="currentpassword"
                label="Current Password"
                placeholder="Current Password"
              />
            </div>
          </div>
          <div class="grid">
            <div class="col-6">
              <InputTextRequire
                v-model="vmodel.newpassword"
                :validation="v$.vmodel.newpassword"
                sectionClass="field mt-1"
                id="newpassword"
                label="New Password"
                placeholder="New Password"
              />
            </div>
            <div class="col-6">
              <InputTextRequire
                v-model="vmodel.confirmpassword"
                :validation="v$.vmodel.confirmpassword"
                sectionClass="field mt-1"
                id="confirmpassword"
                label="Confirm New Password"
                placeholder="Confirm New Password"
              />
            </div>
          </div>
          <div class="mt-3">
            <PrimeButton
              label="Change"
              class="change-button"
              @click="changePassword"
            ></PrimeButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import APIService from "@/services/api-service.js";
import { useVuelidate } from "@vuelidate/core";
import { required, maxLength, minLength, sameAs } from "@vuelidate/validators";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      vmodel: {
        password: "",
        confirmpassword: "",
        newpassword: "",
      },
    };
  },
  validations() {
    return {
      vmodel: {
        password: { required, max: maxLength(20), min: minLength(8) },
        newpassword: { required, max: maxLength(20), min: minLength(8) },
        confirmpassword: { required, max: maxLength(20), min: minLength(8), sameAs: sameAs(this.vmodel.newpassword) }
      },
    };
  },
  methods: {
    async changePassword() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
        this.submitted = true;
        let payload = {
          password: this.vmodel.password,
          newpassword: this.vmodel.newpassword,
        };
        APIService.put(
          "/admin/changepasword",
          payload,
          this.$store.state.userInfo._id
        )
          .then((response) => {
            if (response && response.data && response.data.success) {
              this.$toast.add({
                severity: "success",
                summary: "Success",
                detail: response.data.message,
                life: 3000,
              });
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: response.data.message,
                life: 3000,
              });
            }
          })
          .finally(() => {
            this.submitted = false;
          });
      }
    },
  },
};
</script>