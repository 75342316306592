import DateFormat from "@/libs/DateFormat"
import PrintingMaterial from './printingmaterial'
import PrintingOutInfo from "./printoutinformation"

export default class Quotation {
    constructor(data = {}) {
        return {
            id: data.id || '',
            quotationno: data.quotationno || '',
            quotationdate: DateFormat.formatDate(data.quotationdate) || '',
            printingtype: data.printingtype || '',
            quantity: data.quantity || '',
            status: data.status || '',
            price: data.price || '',
            templateimage: data.templateimage || '',
            templatename: data.templatename || '',
            uploadfile: data.uploadfile || '',
            uploadoriginalfile: data.uploadoriginalfile || '',
            printingmaterial: new PrintingMaterial(data.printingmaterial),
            printoutinformation: new PrintingOutInfo(data.printoutinformation),
            createddate: DateFormat.formatDateTime(data.createddate) || '',
        }
    }
}