import moment from 'moment';

export default {
  formatDate(data, invalid = null) {
    if (!data) {
      return invalid
    }

    return moment(data).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('YYYY-MM-DD')
  },

  formatDateTime(data, invalid = null) {
    if (!data) {
      return invalid
    }

    return moment(data).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('YYYY-MM-DD HH:mm:ss')
  },

  formatTime(data, invalid = null) {
    if (!data) {
      return invalid
    }

    const formatvalue = moment(data).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('hh:mm a')
    if (formatvalue !== 'Invalid date') {
      return formatvalue
    }
    return data
  }
}