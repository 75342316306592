import DateFormat from "@/libs/DateFormat"
import PrintingMaterialForOrder from '@/models/printingmaterialfororder'
import PrintingOutInfoForOrder from "@/models/printoutinformationfororder"

export default class Order {
    constructor(data = {}) {
        return {
            id: data.id || '',
            orderno: data.orderno || '',
            orderdate: DateFormat.formatDate(data.orderdate) || '',
            referquotationnumber: data.referquotationnumber || '',
            quotation: data.quotation || '',
            printingtype: data.printingtype || '',            
            quantity: data.quantity || '',
            status: data.status || '',
            price: data.price || '',
            templateimage: data.templateimage || '',
            templatename: data.templatename || '',
            promocode: data.promocode || '',
            shippingmethod: data.shippingmethod || '',
            paymentmethod: data.paymentmethod || '',
            uploadfile: data.uploadfile || '',
            uploadoriginalfile: data.uploadoriginalfile || '',
            printingmaterial: new PrintingMaterialForOrder(data.printingmaterial),
            printoutinformation: new PrintingOutInfoForOrder(data.printoutinformation),
            createddate: DateFormat.formatDateTime(data.createddate) || '',
        }
    }
}