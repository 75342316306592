<template>
  <div class="ml-8 mr-8 margin-top mb-5">
    <div class="grid">
      <div class="col-12 order-form-title">{{ title }}</div>
      <div class="order-card w-full">
        <div class="col-12">
          <div class="grid">
            <div class="col-4">
              <div class="order-form-sub-title">Template Detail</div>
            </div>
            <div class="col-8">
              <div class="grid">
                <div class="col-6">
                  <InputTextRequire
                    v-model="vmodel.templatename"
                    :validation="v$.vmodel.templatename"
                    sectionClass="field mt-1"
                    id="templatename"
                    label="Template Name"
                    placeholder="Template Name"
                  />
                </div>
                <div class="col-6">
                  <DropDownRequire
                    v-model="vmodel.printingtype"
                    :validation="v$.vmodel.printingtype"
                    sectionClass="field mt-1"
                    id="printingtype"
                    label="Printing Type"
                    placeholder="Select Printing Type"
                    :options="printingTypeList"
                    optionLabel="name"
                    optionValue="id"
                    class="dropdown"
                  />
                </div>
              </div>
              <div class="field">
                <label for="photo" class="form-label">Template Image</label>
                <div
                  class="photo-section cursor-pointer"
                  @click="openFileUpload"
                >
                  <img
                    v-if="vmodel.templateimage !== ''"
                    :src="getImagePath(vmodel.templateimage)"
                    alt=""
                    class="upload-img"
                    height="120"
                    width="120"
                  />
                  <span v-if="vmodel.templateimage == ''" class="upload-icon"></span>
                  <div v-if="vmodel.templateimage == ''" class="upload-text">
                    Upload Template Image
                  </div>
                  <!-- <div v-if="vmodel.image == ''" class="upload-text-small">
                    {{ $t("maximumfilesize") }}
                  </div> -->
                </div>
                <FileUpload
                  ref="photo"
                  name="photo"
                  accept=".png, .jpg, .jpeg"
                  @select="onAdvancedUpload($event)"
                  v-show="false"
                  :aria-describedby="`photo-help`"
                />
              </div>
              <div
                  v-if="
                    v$.vmodel.templateimage.$error &&
                    v$.vmodel.templateimage.required &&
                    v$.vmodel.templateimage.required.$invalid
                  "
                  class="p-error"
                >
                  {{
                    v$.vmodel.templateimage.required.$message.replace("Value", "Template Image")
                  }}
                </div>
              <div class="mt-4">
                <PrimeButton
                  label="Add"
                  class="order-form-button"
                  v-if="!isEditMode"
                  @click="createTemplate"
                ></PrimeButton>
                <PrimeButton
                  label="Edit"
                  class="order-form-button"
                  v-else
                  @click="createTemplate"
                ></PrimeButton>
                <PrimeButton
                  label="Cancel"
                  class="form-cancel-button"
                  @click="onCancel"
                ></PrimeButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import APIService from "@/services/api-service"
import {useVuelidate} from "@vuelidate/core";
import {
  required,
} from "@vuelidate/validators";
export default {
  setup(){
    return {v$:useVuelidate()};
  },
  data() {
    return {
      photo: "",
      isEditMode: false,
      title: "Template / Add",
      printingTypeList: [],
      vmodel: {
        templatename: "",
        printingtype: "",
        templateimage: "",

      },
    };
  },
  validations(){
    return{
      vmodel:{
        templatename:{required},
        printingtype:{required},
        templateimage: {required},
      }
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.title = "Template / Edit";
      this.isEditMode = true;
      this.getTemplateById();
    }
    this.getPrintingType();
  },
  methods: {
    onCancel() {
      this.$router.push({ name: "TemplateList" });
    },
    openFileUpload() {
      this.$refs.photo.choose();
    },
    async createTemplate() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      else {
      let payload = {
        templatename: this.vmodel.templatename,
        printingtype: this.vmodel.printingtype,
        templateimage: this.vmodel.templateimage,
      };
      this.submitted = true;
      if (this.$route.params.id) {
        APIService.put("/template", payload, this.$route.params.id)
          .then((response) => {
            if (response && response.data && response.data.success) {
              this.$toast.add({
                severity: "success",
                summary: "Success",
                detail: response.data.message,
                life: 3000,
              });
              setTimeout(() => {
                this.$router.push({ name: "TemplateList" });
              });
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: response.data.message,
                life: 3000,
              });
            }
          })
          .finally(() => {
            this.submitted = false;
          });
      } else {
        APIService.post("/template", payload)
          .then((response) => {
            if (response && response.data && response.data.success) {
              this.$toast.add({
                severity: "success",
                summary: "Success",
                detail: response.data.message,
                life: 3000,
              });
              setTimeout(() => {
                this.$router.push({ name: "TemplateList" });
              });
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: response.data.message,
                life: 3000,
              });
            }
          })
          .finally(() => {
            this.submitted = false;
          });
      }
      }
    },
    async getTemplateById() {
      APIService.getById("/template", this.$route.params.id)
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.vmodel.templatename = response.data.data.templatename;
            this.vmodel.printingtype = response.data.data.printingtype;
            this.vmodel.templateimage = response.data.data.templateimage;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
            setTimeout(() => {
              this.$router.push({ name: "TemplateList" });
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
    getImagePath(name) {
      return process.env.VUE_APP_TEMPLATE_IMAGE_BASE_PATH + name;
    },
    onAdvancedUpload(fileObj) {
      if (
        !fileObj ||
        (fileObj && !fileObj.files) ||
        (fileObj && fileObj.files && fileObj.files.length === 0)
      ) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Please select valid template",
          life: 3000,
        });
        this.$refs.photo.clear();
      } else if (fileObj && fileObj.files && fileObj.files[0].size > 1000000) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Template size should be less then 1 MB",
          life: 3000,
        });
        this.$refs.photo.clear();
      } else {
        let formData = new FormData();
        formData.append("image", fileObj.files[0]);
        APIService.post("/template/photo", formData, null, true)
          .then((response) => {
            if (response && response.data && response.data.success) {
              // console.log(response.data, 'response.data.data.name');
              this.vmodel.templateimage = response.data.data.name;
              this.$toast.add({
                severity: "success",
                summary: "Success",
                detail: response.data.message,
                life: 3000,
              });
            } else {
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: response.data.message,
                life: 3000,
              });
            }
          })
          .finally(() => {
            this.submitted = false;
            this.$refs.photo.clear();
          });
      }
    },
    getPrintingType() {
      APIService.get("/dropdownlist/dropdown/printingtype")
        .then((response) => {
          if (response && response.data && response.data.data) {
            this.printingTypeList = response.data.data;
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.submitted = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.photo-section {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 12px;
  gap: 4px;
  /* width: 720px; */
  height: 120px;
  background: #ffffff;
  border: 1px dashed rgba(27, 27, 27, 0.4);
  border-radius: 4px;
}

.upload-text {
  width: 156px;
  height: 20px;
  font-family: OpenSans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #1b1b1b;
}

::v-deep(.dropdown) {
  .p-inputtext:hover {
    border: none !important;
}
}
</style>