<template>
  <div class="list-margin">
    <DataTable
      :value="quotations"
      :paginator="true"
      :lazy="true"
      v-model:expandedRows="expandedRows"
      class="p-datatable-lg p-datatable-customers"
      :rows="itemsPerPage"
      :totalRecords="totalRecords"
      dataKey="quotationno"
      :rowHover="true"
      :resizableColumns="true"
      @page="onPage($event)"
      @sort="onSort($event)"
      paginatorTemplate="PrevPageLink PageLinks NextPageLink CurrentPageReport"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} results"
      expandedRowIcon="chevron-up-icon"
      collapsedRowIcon="chevron-down-icon"
    >
      <template #header>
        <div class="flex">
          <div class="col-6 list-header">Quotation List</div>
          <div class="col-6 text-right ml-4 list-header">
            <div class="p-input-icon-right">
              <i class="search-icon cursor-pointer" @click="onSearch" />
              <InputText
                v-model="search"
                placeholder="Search"
                class="search-input"
              />
            </div>
          </div>
        </div>
      </template>
      <template #loading> Loading records, please wait... </template>
      <template #empty> No records found. </template>
      <Column field="expand" :expander="true">
        <template #header>
          <span class="p-column-title"></span>
          <button
            class="p-row-toggler p-link header-toggle"
            type="button"
            @click="arrowToggle"
          >
            <span
              :class="{
                'p-row-toggler-icon pi chevron-down-icon': !isToggled,
                'p-row-toggler-icon pi chevron-up-icon': isToggled,
              }"
            ></span>
          </button>
        </template>
      </Column>
      <Column field="quotationno" header="Quotation #" sortable="true">
        <template #body="data">
          <span class="underline">#{{ data.data.quotationno }}</span>
        </template>
      </Column>
      <Column
        field="quotationdate"
        header="Quotation Date"
        sortable="true"
      ></Column>
      <Column
        field="printingtype"
        header="Printing Type"
        sortable="true"
      ></Column>
      <Column field="quantity" header="Quantity" sortable="true"></Column>
      <Column field="status" header="Status" sortable="true">
        <template #body="data">
          <Chip
            v-if="data.data.status == 'accepted'"
            :label="data.data.status"
            class="chip-style-for-quotation-accept"
          />
          <Chip
            v-if="data.data.status == 'waiting'"
            :label="data.data.status"
            class="chip-style-for-quotation-waiting"
          />
          <Chip
            v-if="data.data.status == 'denied'"
            :label="data.data.status"
            class="chip-style-for-quotation-denied"
          />
        </template>
      </Column>
      <Column field="price" header="Price">
        <template #body="data">
          <div class="flex">
            <InputNumber
              v-model="data.data.price"
              placeholder="Price"
              class="text-field"
              :disabled="data.data.status !== 'waiting'"
            />
            <PrimeButton
              v-if="data.data.status === 'waiting'"
              label="Send"
              class="send-button ml-2 mt-2"
              @click="showSendPopup(data.data.id, data.data.price)"
            ></PrimeButton>
          </div>
        </template>
      </Column>

      <template #expansion="slotProps">
        <div class="col-12">
          <span class="quotation-text"
            >Quotation #{{ slotProps.data.quotationno }}</span
          >
        </div>
        <div class="row col-12 flex">
          <div class="col-4">
            <span class="quotation-sub-text">Quotation Detail</span>
            <div class="grid mt-2">
              <div class="col-6 quotation-detail-label text-left">
                Quotation Number
              </div>
              <div class="col-6 quotation-detail-value text-right">
                #{{ slotProps.data.quotationno }}
              </div>
            </div>
            <div class="grid mt-2">
              <div class="col-6 quotation-detail-label text-left">
                Quotation Date
              </div>
              <div class="col-6 quotation-detail-value text-right">
                {{ slotProps.data.quotationdate }}
              </div>
            </div>
            <div class="grid mt-2">
              <div class="col-6 quotation-detail-label text-left">Status</div>
              <div class="col-6 quotation-detail-value text-right">
                <Chip
                  v-if="slotProps.data.status == 'waiting'"
                  :label="slotProps.data.status"
                  class="chip-style-for-quotation-waiting chip-margin"
                />
                <Chip
                  v-if="slotProps.data.status == 'accepted'"
                  :label="slotProps.data.status"
                  class="chip-style-for-quotation-accept chip-margin"
                />
                <Chip
                  v-if="slotProps.data.status == 'denied'"
                  :label="slotProps.data.status"
                  class="chip-style-for-quotation-denied chip-margin"
                />
              </div>
            </div>
            <div class="grid mt-3">
              <div class="col-3 text-left">
                <img
                  :src="getImagePath(slotProps.data.templateimage)"
                  alt=""
                  style="height: 72px; width: 72px"
                />
              </div>
              <div class="col-6 quotation-detail-value text-left">
                <div class="row mt-3 quotation-detail-label">
                  {{ slotProps.data.printingtype }}
                </div>
                <div class="row">
                  {{ slotProps.data.templatename }}
                </div>
              </div>
              <div class="col-3 quotation-detail-value text-right mt-4">
                × {{ slotProps.data.quantity }}
              </div>
            </div>
          </div>
          <div class="col-4">
            <span class="quotation-sub-text">Printing Material</span>
            <div class="grid mt-2">
              <div class="col-6 quotation-detail-label text-left">
                Paper Type
              </div>
              <div class="col-6 quotation-detail-value text-right">
                {{ slotProps.data.printingmaterial.papertype }}
              </div>
            </div>
            <div class="grid mt-2">
              <div class="col-6 quotation-detail-label text-left">
                Paper Color
              </div>
              <div class="col-6 quotation-detail-value text-right">
                {{ slotProps.data.printingmaterial.papercolor }}
              </div>
            </div>
            <div class="grid mt-2">
              <div class="col-6 quotation-detail-label text-left">
                Font Type
              </div>
              <div class="col-6 quotation-detail-value text-right">
                {{ slotProps.data.printingmaterial.fonttype }}
              </div>
            </div>
            <div class="grid mt-2">
              <div class="col-6 quotation-detail-label text-left">
                Font Color
              </div>
              <div class="col-6 quotation-detail-value text-right">
                <ColorPicker
                  v-model="slotProps.data.printingmaterial.fontcolor"
                  class="color-field"
                  disabled="true"
                />
                {{ slotProps.data.printingmaterial.fontcolor }}
              </div>
            </div>
          </div>
          <div class="col-4">
            <span class="quotation-sub-text">Print Out Information</span>
            <div class="grid mt-2">
              <div class="col-6 quotation-detail-label text-left">
                Company Name
              </div>
              <div class="col-6 quotation-detail-value text-right">
                {{ slotProps.data.printoutinformation.companyname }}
              </div>
            </div>
            <div class="grid mt-2">
              <div class="col-6 quotation-detail-label text-left">Name</div>
              <div class="col-6 quotation-detail-value text-right">
                {{ slotProps.data.printoutinformation.name }}
              </div>
            </div>
            <div class="grid mt-2">
              <div class="col-6 quotation-detail-label text-left">Title</div>
              <div class="col-6 quotation-detail-value text-right">
                {{ slotProps.data.printoutinformation.title }}
              </div>
            </div>
            <div class="grid mt-2">
              <div class="col-6 quotation-detail-label text-left">
                Business Phone Number
              </div>
              <div class="col-6 quotation-detail-value text-right">
                {{ slotProps.data.printoutinformation.businessphonenumber }}
              </div>
            </div>
            <div class="grid mt-2">
              <div class="col-6 quotation-detail-label text-left">
                Direct Line
              </div>
              <div class="col-6 quotation-detail-value text-right">
                {{ slotProps.data.printoutinformation.directline }}
              </div>
            </div>
            <div class="grid mt-2">
              <div class="col-6 quotation-detail-label text-left">
                Mobile Number
              </div>
              <div class="col-6 quotation-detail-value text-right">
                {{ slotProps.data.printoutinformation.mobilenumber }}
              </div>
            </div>
            <div class="grid mt-2">
              <div class="col-6 quotation-detail-label text-left">Address</div>
              <div class="col-6 quotation-detail-value text-right truncate">
                {{ slotProps.data.printoutinformation.address }}
              </div>
            </div>
            <div class="grid mt-2">
              <div class="col-6 quotation-detail-label text-left">Email</div>
              <div class="col-6 quotation-detail-value text-right">
                {{ slotProps.data.printoutinformation.email }}
              </div>
            </div>
            <div class="grid mt-2">
              <div class="col-6 quotation-detail-label text-left">
                Uploaded File
              </div>
              <div class="col-6 quotation-detail-value text-right">
                <a
                  class="underline file-color cursor-pointer"
                  @click="getURL(slotProps.data.uploadfile)"
                  >{{ slotProps.data.uploadoriginalfile }}</a
                >
              </div>
            </div>
          </div>
        </div>
      </template>
    </DataTable>
    <div>
      <ConfirmPopup
        :message="message"
        :header="header"
        :show-popup="showPopup"
        @confirmYes="onSend"
        @confirmNo="showPopup = false"
      ></ConfirmPopup>
    </div>
  </div>
</template>

<script>
import APIService from "@/services/api-service";
import QuotationFactory from "@/factories/quotation";

export default {
  data() {
    return {
      currentPage: 0,
      itemsPerPage: 10,
      search: "",
      totalRecords: 0,
      sortColumn: "id",
      sortDir: "asc",
      showPopup: false,
      isToggled: false,
      quotationIdForSend: "",
      quotationPriceForSend: 0,
      expandedRows: [],
      quotations: [],
    };
  },
  mounted() {
    this.getQuotationList();
  },
  methods: {
    expandAll() {
      this.expandedRows = this.quotations;
    },
    collapseAll() {
      this.expandedRows = null;
    },
    arrowToggle() {
      if (this.isToggled) {
        this.collapseAll();
      } else {
        this.expandAll();
      }
      return (this.isToggled = !this.isToggled);
    },
    getImagePath(name) {
      return process.env.VUE_APP_TEMPLATE_IMAGE_BASE_PATH + name;
    },
    getTextPath(name) {
      return process.env.VUE_APP_ORDER_TEXT_BASE_PATH + name;
    },
    getURL(file) {
      const url = process.env.VUE_APP_ORDER_TEXT_BASE_PATH + file;
      window.open(url, "_blank");
    },
    getQuotationList() {
      this.quotations = [];
      APIService.get(
        `/quotation/${this.currentPage}/${this.itemsPerPage}/${this.sortColumn}/${this.sortDir}/${this.search}`
      ).then((response) => {
        if (response && response.data) {
          this.totalRecords = response.data.totalrecord;
          this.quotations = QuotationFactory.createFromJsonArray(
            response.data.data
          );
        }
      });
    },
    showSendPopup(quotationId, quatationPrice) {
      this.message = "Are you sure you want to send this price?";
      this.header = "Send Confirmation";
      this.quotationIdForSend = quotationId;
      this.quotationPriceForSend = quatationPrice;
      setTimeout(() => {
        this.showPopup = true;
      }, 100);
    },
    onSend() {
      let payload = {
        price: this.quotationPriceForSend,
      };

      APIService.put("/quotation", payload, this.quotationIdForSend)
        .then((response) => {
          if (response && response.data) {
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: response.data.message,
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .finally(() => {
          this.showPopup = false;
        });
    },
    onPage(pageData) {
      this.currentPage = pageData.first;
      this.getQuotationList();
    },
    onSort(sortData) {
      let sortField = sortData.sortField;
      if (sortData.sortField === "createdby") {
        sortField = "createdby";
      } else if (sortData.sortField === "createddate") {
        sortField = "createddate";
      }
      this.sortColumn = sortField;
      this.sortDir = sortData.sortOrder === 1 ? "asc" : "dsc";
      this.currentPage = 0;

      this.getQuotationList();
    },
    onSearch() {
      this.search = this.search.trimStart().trimEnd();
      this.currentPage = 0;
      this.getQuotationList();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/datatable.scss";

.header-toggle {
  width: 2rem;
  height: 2rem;
  color: #6c757d;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: box-shadow 0.15s;
}

.card-margin-total {
  margin-left: 110px;
}

.card-margin-promo {
  margin-left: -19px;
}

.card-margin-discount {
  margin-left: 84px;
}

::v-deep(.expand-table) {
  .p-datatable-table {
    padding-bottom: 8px;
  }

  .p-datatable-wrapper {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

::v-deep(.text-field) {
  .p-inputtext {
    height: 48px;
    width: 120px;
    margin-top: 8px;
  }
}

::v-deep(.color-field) {
  .p-inputtext {
    width: 20px;
    height: 20px;
    background: #1b1b1b;
    border-radius: 2px;
  }
}

.chip-margin {
  margin-left: auto;
}

.underline {
  text-decoration-line: underline;
}

.file-color {
  color: #ee3a3a;
}

.truncate {
  text-overflow: ellipsis;
  word-break: break-all;
  overflow: hidden;
  white-space: nowrap;
  height: 23px;
}

.truncate:hover {
  overflow: visible;
  white-space: normal;
  height: auto;
}
</style>